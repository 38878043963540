<template>
  <v-card>
    <v-card-title>
      <span class="title-card">DÉTAIL DU COMPTE</span>
      <v-spacer></v-spacer>
      <small class="text-muted font-weight-italic">inscrit le : {{ profilUser.creationDate }}</small>
    </v-card-title>
    <v-card-text>
      <v-container>
        <form>
          <!-- Status -->
          <div class="form-group">
            <label for="status">Status</label>
            <input 
              disabled 
              type="text" 
              :value="getStatus" 
              :class="['form-control', getStatusClass]" 
              id="status"
            >
          </div>
          <v-divider></v-divider>

          <!-- Jetons -->
          <div class="form-group">
            <label for="tokens">Jetons</label>
            <input 
              disabled 
              type="text" 
              :value="getTokens" 
              class="form-control" 
              id="tokens"
            >
          </div>
          <v-divider></v-divider>

          <!-- Expiration -->
          <div class="form-group">
            <label for="expiration">Date d'expiration</label>
            <input 
              disabled 
              type="text" 
              :value="getExpirationDate" 
              :class="['form-control', {'text-danger': isExpired}]" 
              id="expiration"
            >
          </div>
          <v-divider></v-divider>
        </form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "portefeuille",  
  props: {
    profilUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    getStatus() {
      console.log('Données complètes reçues dans portefeuille:', {
        profilUser: this.profilUser,
        numTokens: this.profilUser?.numTokens,
        tokensIsBlocked: this.profilUser?.tokensIsBlocked,
        tokenExpirationDate: this.profilUser?.tokenExpirationDate
      });
      
      if (!this.profilUser || this.profilUser.numTokens === null || this.profilUser.numTokens === undefined) {
        return 'Pas d\'achat effectué';
      }
      return this.profilUser.tokensIsBlocked ? 'Expiré' : 'Actif';
    },
    
    getStatusClass() {
      if (!this.profilUser || this.profilUser.numTokens === null || this.profilUser.numTokens === undefined) {
        return 'text-muted';
      }
      return this.profilUser.tokensIsBlocked ? 'text-danger' : 'text-success';
    },
    
    getTokens() {
      console.log('Valeur des jetons:', this.profilUser?.numTokens);
      if (!this.profilUser || this.profilUser.numTokens === null || this.profilUser.numTokens === undefined) {
        return 'Aucun jeton';
      }
      return `${this.profilUser.numTokens} jetons`;
    },
    
    getExpirationDate() {
      console.log('Date d\'expiration:', this.profilUser?.tokenExpirationDate);
      if (!this.profilUser || !this.profilUser.tokenExpirationDate) {
        return 'Non disponible';
      }
      return new Date(this.profilUser.tokenExpirationDate).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    
    isExpired() {
      if (!this.profilUser || !this.profilUser.tokenExpirationDate) return false;
      const isExpired = new Date(this.profilUser.tokenExpirationDate) < new Date();
      console.log('Statut d\'expiration:', {
        date: this.profilUser?.tokenExpirationDate,
        isExpired
      });
      return isExpired;
    }
  }
}
</script>

<style scoped>
.title-card {
  font-weight: 700;
  font-size: 12px;
}

.text-danger {
  color: #dc3545 !important;
}

.text-success {
  color: #28a745 !important;
}

.text-muted {
  color: #6c757d !important;
}

.form-control:disabled {
  font-weight: bold;
}
</style>
