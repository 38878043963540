<template>
    <v-app>
        <v-container>
            <v-card>
                <v-card-title>
                    <span>GESTION DES MODELES</span>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="primary" 
                        @click="showForm(true)"
                        :disabled="loading"
                    >
                        AJOUTER UN MODELE
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>Liste des Modèles disponibles</v-card-subtitle>
                
                <v-data-table
                    :headers="headers"
                    :items="AppsertList"
                    :loading="loading"
                    :search="search"
                >
                    <template v-slot:[`item.creationDate`]="{ item }">
                        {{ formatDate(item.creationDate) }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            icon
                            small
                            color="primary"
                            @click="editAppSet(item)"
                            :disabled="loading"
                        >
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>

        <!-- Dialog pour le formulaire -->
        <v-dialog
            v-model="showDialog"
            max-width="500px"
            persistent
        >
            <v-card>
                <v-card-title>
                    {{ formTitle }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeForm">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-select
                            v-model="tokenOject.model"
                            :items="modelOptions"
                            label="Modèle"
                            item-text="text"
                            item-value="value"
                            :rules="[rules.required, rules.validModel]"
                            outlined
                            dense
                            :hint="getModelHint(tokenOject.model)"
                            persistent-hint
                            :disabled="!createMode"
                        ></v-select>

                        <v-text-field
                            v-model="tokenOject.version"
                            label="Version"
                            :rules="[rules.required, rules.versionFormat]"
                            outlined
                            dense
                            hint="Format: x.y.z (ex: 1.0.0)"
                            persistent-hint
                        ></v-text-field>

                        <v-text-field
                            v-model="tokenOject.updateUrl"
                            label="URL de Mise à Jour"
                            :rules="[rules.required, rules.url]"
                            outlined
                            dense
                        ></v-text-field>

                        <v-btn
                            color="primary"
                            :loading="loading"
                            :disabled="!valid"
                            @click="submitForm"
                            block
                        >
                            {{ createMode ? 'AJOUTER' : 'MODIFIER' }}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Snackbar pour les notifications -->
        <v-snackbar
            v-model="snackbar.show"
            :color="snackbar.color"
            :timeout="4000"
            top
            right
        >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar.show = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay :value="loading">
            <v-progress-circular 
                color="primary" 
                indeterminate
            ></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '@/Config/globalConst';
import { EventBus } from '@/eventBus';

export default {
    name: "UpdateView",
    
    data() {
        return {
            AppsertList: [],
            createMode: true,
            loading: false,
            valid: false,
            showDialog: false,
            search: "",
            
            // ����������tat pour les notifications
            snackbar: {
                show: false,
                text: '',
                color: 'success'
            },

            // Objet pour le formulaire
            tokenOject: {
                version: '',
                model: '',
                updateUrl: '',
                appSetCode: null
            },

            // Règles de validation
            rules: {
                required: v => !!v || 'Ce champ est requis',
                url: v => {
                    const pattern = /^https?:\/\/.+\..+/;
                    return pattern.test(v) || 'URL invalide'
                },
                versionFormat: v => {
                    const pattern = /^\d+\.\d+\.\d+$/;
                    return pattern.test(v) || 'Format invalide (utilisez x.y.z)'
                }
            },

            headers: [
                { text: 'Modèle', value: 'model' },
                { text: 'Version', value: 'version' },
                { text: 'Nombre de Devices', value: 'nbDevices' },
                { text: 'URL de Mise à Jour', value: 'updateUrl' },
                { text: 'Date de Création', value: 'creationDate' },
                { text: 'Actions', value: 'actions', sortable: false }
            ],

            // Options pour le select des modèles
            modelOptions: [
                { 
                    text: 'huawei', 
                    value: 'huawei',
                    description: 'Modèle pour les appareils Huawei'
                },
                { 
                    text: 'android', 
                    value: 'android',
                    description: 'Modèle pour les appareils Android'
                },
                { 
                    text: 'ios', 
                    value: 'ios',
                    description: 'Modèle pour les appareils IOS'
                }
            ],
        }
    },

    computed: {
        formTitle() {
            return this.createMode ? 'Ajouter un modèle' : 'Modifier le modèle'
        }
    },

    methods: {
        // Gestion du formulaire
        showForm(isCreate = true) {
            this.createMode = isCreate;
            if (isCreate) {
                this.resetForm();
            }
            this.showDialog = true;
        },

        closeForm() {
            this.showDialog = false;
            this.resetForm();
        },

        resetForm() {
            this.$refs.form?.reset();
            this.tokenOject = {
                version: '',
                model: '',
                updateUrl: '',
                appSetCode: null
            };
        },

        async submitForm() {
            if (!this.$refs.form.validate()) return;
            
            // Vérification supplémentaire de la version
            const existingVersion = this.AppsertList.find(
                item => item.model === this.tokenOject.model && 
                       item.version === this.tokenOject.version
            );

            if (existingVersion && this.createMode) {
                this.showNotification(
                    'Cette version existe déjà pour ce modèle', 
                    'warning'
                );
                return;
            }

            try {
                this.loading = true;
                if (this.createMode) {
                    await this.createAppsert();
                } else {
                    await this.updateAppSet();
                }
                this.closeForm();
                await this.getAllAppsert();
            } catch (error) {
                this.showNotification(error.message, 'error');
            } finally {
                this.loading = false;
            }
        },

        // Notifications
        showNotification(message, type = 'success') {
            this.snackbar = {
                show: true,
                text: message,
                color: type
            };
        },

        // Formatage de date
        formatDate(dateString) {
            if (!dateString) return '';
            return new Date(dateString).toLocaleDateString('fr-FR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        },

        // API calls
        async createAppsert() {
            const graphqlQuery = {
                query: `mutation {
                    createAppSet(
                        model: "${this.tokenOject.model}",
                        version: "${this.tokenOject.version}",
                        updateUrl: "${this.tokenOject.updateUrl}"
                    ) {
                        success
                        error
                    }
                }`
            };

            const response = await axios.post(baseUrl, graphqlQuery);
            const result = response.data.data.createAppSet;

            if (!result.success) {
                throw new Error(result.error || 'Erreur lors de la création');
            }

            this.showNotification('Modèle créé avec succès');
        },

        // Modifier un modèle existant
        editAppSet(item) {
            this.createMode = false;
            // Vérifier si le modèle existe dans les options
            const modelOption = this.modelOptions.find(opt => opt.value === item.model);
            
            if (!modelOption) {
                this.showNotification(
                    `Le modèle "${item.model}" n'est pas dans la liste des modèles disponibles`,
                    'error'
                );
                return;
            }

            this.tokenOject = {
                appSetCode: item.appSetCode,
                model: modelOption.value, // Utiliser la valeur de l'option trouvée
                version: item.version,
                updateUrl: item.updateUrl
            };
            this.showDialog = true;
        },

        // Mise à jour d'un AppSet
        async updateAppSet() {
            const graphqlQuery = {
                query: `mutation {
                    updateAppSet(
                        appSetCode: "${this.tokenOject.appSetCode}",
                        model: "${this.tokenOject.model}",
                        version: "${this.tokenOject.version}",
                        updateUrl: "${this.tokenOject.updateUrl}"
                    ) {
                        success
                        error
                    }
                }`
            };

            const response = await axios.post(baseUrl, graphqlQuery);
            const result = response.data.data.updateAppSet;

            if (!result.success) {
                throw new Error(result.error || 'Erreur lors de la mise à jour');
            }

            this.showNotification('Modèle mis à jour avec succès');
        },

        getModelHint(model) {
            const option = this.modelOptions.find(opt => opt.value === model);
            return option ? option.description : '';
        },

        async getAllAppsert() {
            try {
                console.log('Starting getAllAppsert...'); // Debug
                this.loading = true;
                const graphqlQuery = {
                    query: `query {
                        allAppSet {
                            edges {
                                node {
                                    appSetCode
                                    model
                                    version
                                    nbDevices
                                    updateUrl
                                    creationDate
                                }
                            }
                        }
                    }`
                };
                
                // Test de la requête directement
                console.log('Sending GraphQL query:', graphqlQuery);
                
                const response = await axios.post(baseUrl, graphqlQuery);
                console.log('Raw API Response:', response.data);
                
                // Vérifier si la réponse contient des données
                if (!response.data?.data?.allAppSet?.edges) {
                    console.warn('No edges found in response');
                    return;
                }

                // Vérifier le format des données
                const edges = response.data.data.allAppSet.edges;
                console.log('Edges received:', edges);

                this.AppsertList = edges.map(edge => edge.node);
                console.log('Processed AppsertList:', this.AppsertList);
                
                const devices = {
                    android: 0,
                    ios: 0,
                    huawei: 0
                };
                
                this.AppsertList.forEach(item => {
                    // S'assurer que le modèle est en minuscules pour la comparaison
                    const model = (item.model || '').toLowerCase();
                    const nbDevices = parseInt(item.nbDevices) || 0;
                    
                    console.log(`Processing device - Model: ${model}, Count: ${nbDevices}`);
                    
                    switch(model) {
                        case 'android':
                            devices.android += nbDevices;
                            break;
                        case 'ios':
                            devices.ios += nbDevices;
                            break;
                        case 'huawei':
                            devices.huawei += nbDevices;
                            break;
                        default:
                            console.warn(`Unknown device model: ${model}`);
                    }
                });
                
                console.log('Final device counts:', devices);
                
                // Vérifier si les compteurs sont > 0 avant d'émettre
                if (devices.android > 0 || devices.ios > 0 || devices.huawei > 0) {
                    console.log('Emitting device counts:', devices);
                    EventBus.$emit('devices-count-updated', devices);
                } else {
                    console.warn('All device counts are 0, something might be wrong');
                }
                
            } catch (error) {
                console.error('Error in getAllAppsert:', error);
                console.error('Error details:', {
                    message: error.message,
                    response: error.response?.data
                });
            } finally {
                this.loading = false;
            }
        }
    },

    mounted() {
        // Appeler getAllAppsert au montage du composant
        this.getAllAppsert();
        
        // Mettre en place un polling toutes les 30 secondes
        this.pollingInterval = setInterval(() => {
            this.getAllAppsert();
        }, 30000);
    },

    beforeDestroy() {
        // Nettoyer l'intervalle quand le composant est détruit
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
    }
}
</script>

<style scoped>
.v-data-table {
    width: 100%;
}

.v-card {
    margin-bottom: 20px;
}
</style>