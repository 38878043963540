<template>
    <v-app>
        <v-main>
            <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-4">
                <div class="row">
                    <div class="col-sm">
                        <div class="usage"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TRADUCTION</h3></v-card-title>
                            <v-card-subtitle>Jetons utilisés pour les traductions</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenTraductionCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalCoinUsedForTranslate.length }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm">
                        <v-card elevation="3">
                            <div class="usage"></div>
                            <v-card-title><h3>CORRECTION</h3></v-card-title>
                            <v-card-subtitle>Jetons utilisés pour les corrections</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenCorrectionCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalCoinUsedForCorrection.length }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm">
                        <v-card elevation="3">
                            <div class="free"></div>
                            <v-card-title><h3>JETONS OFFERTS</h3></v-card-title>
                            <v-card-subtitle>Jetons offerts aux utilisateurs</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokensFreeCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ amountFree }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </div><br>

                <div class="row">
                    <div class="col-sm-4">
                        <div class="blocked"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>JETONS BLOQUES</h3></v-card-title>
                            <v-card-subtitle>Total des jetons bloqués</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ amountBlocked }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm-4">
                        <div class="usage"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TOTAL UTILISATION EN XOF</h3></v-card-title>
                            <v-card-subtitle>Total des jetons utilisés en XOF</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalPriceTranslate + totalPriceCorrect }} XOF</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm-4">
                        <div class="free"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TOTAL JETONS DISTRIBUES</h3></v-card-title>
                            <v-card-subtitle>Total des jetons distribué en XOF</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalPriceForFree }} XOF</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </div><br>


                

            </v-sheet>
            <v-container fluid>
                <!-- Tableau des achats -->
                <div class="row mb-6">
                    <div class="col">
                        <v-card>
                            <v-card-title>
                                <h4>HISTORIQUE DES ACHATS</h4>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Rechercher"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table
                                :headers="purchaseHeaders"
                                :items="purchaseHistory"
                                :search="search"
                                :loading="isLoadingPurchaseHistory"
                                :items-per-page="10"
                                :footer-props="{
                                    'items-per-page-options': [10, 20, 50, 100, -1],
                                    'items-per-page-text': 'Lignes par page'
                                }"
                            >
                                <template v-slot:[`item.status`]="{ item }">
                                    <v-chip x-small :color="getStatusColor(item.status)">
                                        <span class="type font-weight-bold">{{ item.status }}</span>
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.paymentType`]="{ item }">
                                    <v-chip x-small color="primary">
                                        <span class="type font-weight-bold">{{ item.paymentType }}</span>
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.amount`]="{ item }">
                                    <span :class="{ 'failed-transaction': item.status === 'failed' }">
                                        {{ item.amount }}
                                    </span>
                                </template>
                                <template v-slot:no-data>
                                    <div class="text-center">
                                        <v-progress-circular
                                            v-if="isLoadingPurchaseHistory"
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                        <span v-else>Aucune donnée disponible</span>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </div>
                </div>

                <!-- Tableau des activités -->
                <div class="row">
                    <div class="col">
                        <v-card>
                            <v-card-title>
                                <h4>HISTORIQUE DES ACTIVITÉS</h4>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="activitySearch"
                                    append-icon="mdi-magnify"
                                    label="Rechercher"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table
                                :headers="activityHeaders"
                                :items="activityHistory"
                                :search="activitySearch"
                                :loading="loading"
                            >
                                <template v-slot:[`item.transactionType`]="{ item }">
                                    <v-chip x-small :color="getActivityColor(item.transactionType)">
                                        <span class="type font-weight-bold">{{ item.transactionType }}</span>
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-card>
                    </div>
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { baseUrl } from '@/Config/globalConst';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    name:"transaction",
    computed: {
        ...mapState({
            currentMode: state => state.environment.isProduction,
            userList: state => state.users.userList
        }),
        userEmailMap() {
            console.log('🔄 Recalcul de userEmailMap');
            console.log('📋 État actuel:', {
                userListExists: !!this.userList,
                userListLength: this.userList?.length,
                mode: this.currentMode ? 'Production' : 'Développement'
            });
            
            const map = new Map();
            if (this.userList && this.userList.length > 0) {
                this.userList.forEach(user => {
                    if (user.node && user.node.email) {
                        map.set(user.node.email.toLowerCase(), {
                            name: user.node.name,
                            firstname: user.node.firstname,
                            lastCvUpdate: user.node.lastCvUpdate
                        });
                    }
                });
            }
            console.log(`📍 userEmailMap créée avec ${map.size} entrées`);
            return map;
        }
    },
    data() {
    return {
        singleSelect: false,
        search: '',           // Pour la recherche globale
        activitySearch: '',   // Pour la recherche des activités

        // États de chargement
        isLoadingTokenTraductionCount: false,
        isLoadingTokenCorrectionCount: false,
        isLoadingTokensFreeCount: false,
        isLoadingTokenBlockedCount: false,
        loading: false,
            isLoadingPurchaseHistory: true,

        // Compteurs
        amountFree: 0,
        amountBlocked: 0,
        totalPriceTranslate: 0,
        totalPriceCorrect: 0,
        totalPriceForFree: 0,

        // Tableaux de transactions par type
        totalCoinUsedForTranslate: [],
        totalCoinUsedForCorrection: [],
        totalCoinsForFree: [],
        totalCoinsBlocked: [],

        // Séparation des transactions en deux tableaux
        purchaseHistory: [],    // Pour les achats
        activityHistory: [],    // Pour les autres activités

        // En-têtes spécifiques pour chaque tableau
        purchaseHeaders: [
            { text: 'Nom', value: 'user.name' },
            { text: 'Prenoms', value: 'user.firstname' },
            { text: 'Type de paiement', value: 'paymentType' },
            { text: 'Prix', value: 'amount' },
            { text: 'Date', value: 'timestamp' },
            { text: 'Devise', value: 'currency' },
                { text: 'Statut', value: 'status' }
        ],
        activityHeaders: [
            { text: 'Nom', value: 'user.name' },
            { text: 'Prenoms', value: 'user.firstname' },
            { text: 'Type d\'activité', value: 'transactionType' },
            { text: 'Libellé', value: 'label' },
            { text: 'Nbre jetons', value: 'amount' },
            { text: 'Date', value: 'timestamp' }
        ]
    }
},

    methods: {
        async getAllTransactions() {
            try {
                console.log('🔍 Mode actuel:', this.currentMode ? 'Production' : 'Développement');
                console.log('👥 Liste des utilisateurs dans le store:', this.userList?.length);
                console.log('🗺️ UserEmailMap:', Array.from(this.userEmailMap.entries()));
                
                this.setLoadingStates(true);
                this.isLoadingPurchaseHistory = true;

                const response = await this.fetchTransactionData();
                if (!response?.data?.data) {
                    throw new Error("Données invalides reçues de l'API");
                }

                const { showWebTransaction, allTransactionHistory } = response.data.data;
                console.log('📊 Transactions reçues:', {
                    achats: showWebTransaction?.edges?.length || 0,
                    activites: allTransactionHistory?.length || 0
                });

                // Optimisation: Traitement par lots de 100 transactions
                const BATCH_SIZE = 100;
                const purchases = showWebTransaction.edges;
                const processedPurchases = [];

                // Traitement par lots pour éviter le blocage du thread principal
                const processBatch = (startIndex) => {
                    const endIndex = Math.min(startIndex + BATCH_SIZE, purchases.length);
                    const batch = purchases.slice(startIndex, endIndex);

                    batch.forEach(edge => {
                        const email = edge.node.customerEmail?.toLowerCase();
                        const userData = email ? this.userEmailMap.get(email) : null;
                        console.log('🔄 Traitement transaction:', {
                            email,
                            trouvé: !!userData,
                            données: edge.node
                        });
                        
                        // Vérifier si l'utilisateur existe dans la liste filtrée par mode
                        if (userData) {
                        processedPurchases.push({
                            timestamp: new Date(edge.node.createdAt).toLocaleString('fr-FR'),
                            user: {
                                    name: userData.name || edge.node.customerName || 'N/A',
                                    firstname: userData.firstname || ''
                            },
                            amount: parseFloat(edge.node.amount),
                                paymentType: edge.node.paymentType,
                                currency: edge.node.currency,
                                status: edge.node.status,
                                lastCvUpdate: userData.lastCvUpdate || 'Non disponible'
                        });
                        }
                    });

                    if (endIndex < purchases.length) {
                        setTimeout(() => {
                            processBatch(endIndex);
                        }, 0);
                    } else {
                        this.$nextTick(() => {
                            console.log('💾 Achats traités:', processedPurchases.length);
                            this.purchaseHistory = processedPurchases;
                            this.isLoadingPurchaseHistory = false;
                        });
                    }
                };

                processBatch(0);

                const processActivities = () => {
                    console.log('🔄 Début du traitement des activités');
                    const activities = allTransactionHistory
                        .filter(transaction => {
                            const userEmail = transaction.user?.email?.toLowerCase();
                            const isValid = userEmail && this.userEmailMap.has(userEmail);
                            console.log('📝 Vérification activité:', {
                                email: userEmail,
                                trouvé: isValid,
                                type: transaction.transactionType
                            });
                            return isValid;
                        })
                        .map(transaction => ({
                            ...transaction,
                            timestamp: new Date(transaction.timestamp).toLocaleString('fr-FR')
                        }));

                    this.$nextTick(() => {
                        console.log('💾 Activités traitées:', activities.length);
                        this.activityHistory = activities;
                        this.calculateActivityTotals();
                    });
                };

                setTimeout(processActivities, 0);

            } catch (error) {
                console.error('❌ Erreur lors de la récupération des transactions:', error);
                this.isLoadingPurchaseHistory = false;
            } finally {
                this.setLoadingStates(false);
            }
        },

        setLoadingStates(isLoading) {
            this.loading = isLoading;
            this.isLoadingTokenTraductionCount = isLoading;
            this.isLoadingTokenCorrectionCount = isLoading;
            this.isLoadingTokensFreeCount = isLoading;
            if (!isLoading) {
                setTimeout(() => {
                    if (this.purchaseHistory.length === 0) {
                        this.isLoadingPurchaseHistory = false;
                    }
                }, 2000);
            }
        },

        async fetchTransactionData() {
            const graphqlQuery = {
                query: `
                    query {
                        showWebTransaction {
                            edges {
                                node {
                                    id
                                    paymentType
                                    currency 
                                    customerEmail
                                    customerName  
                                    createdAt
                                    status
                                    amount
                                }
                            }
                        }
                        allTransactionHistory {
                            id
                            user {
                                id
                                name
                                firstname
                                email
                            }
                            amount
                            label
                            price
                            transactionType
                            timestamp
                        }
                    }
                `
            };
            return await axios.post(baseUrl, graphqlQuery);
        },

        resetCounters() {
            this.totalPriceTranslate = 0;
            this.totalPriceCorrect = 0;
            this.totalPriceForFree = 0;
            this.amountFree = 0;
            this.amountBlocked = 0;
        },

        calculateActivityTotals() {
            const PRICE_PER_TOKEN = 250;

            this.activityHistory.forEach(transaction => {
                console.log('Calcul des totaux pour la transaction:', {
                    label: transaction.label,
                    montant: transaction.amount,
                    prix: transaction.amount * PRICE_PER_TOKEN
                });

                switch (transaction.label) {
                    case 'Traduction de CV':
                        console.log('➕ Ajout à totalCoinUsedForTranslate');
                        this.totalCoinUsedForTranslate.push(transaction);
                        this.totalPriceTranslate += transaction.amount * PRICE_PER_TOKEN;
                        break;
                    case 'Correction de CV':
                        console.log('➕ Ajout à totalCoinUsedForCorrection');
                        this.totalCoinUsedForCorrection.push(transaction);
                        this.totalPriceCorrect += transaction.amount * PRICE_PER_TOKEN;
                        break;
                    case 'Jeton d\'inscrition !':
                        console.log('➕ Ajout à totalCoinsForFree');
                        this.totalCoinsForFree.push(transaction);
                        this.amountFree += transaction.amount;
                        this.totalPriceForFree += transaction.amount * PRICE_PER_TOKEN;
                        break;
                    case 'Jétons Bloqués':
                        console.log('➕ Ajout à totalCoinsBlocked');
                        this.totalCoinsBlocked.push(transaction);
                        this.amountBlocked += transaction.amount;
                        break;
                }
            });

            console.log('Totaux finaux:', {
                traduction: {
                    jetons: this.totalCoinUsedForTranslate.length,
                    prix: this.totalPriceTranslate
                },
                correction: {
                    jetons: this.totalCoinUsedForCorrection.length,
                    prix: this.totalPriceCorrect
                },
                gratuit: {
                    jetons: this.amountFree,
                    prix: this.totalPriceForFree
                },
                bloqué: this.amountBlocked
            });
        },

        getStatusColor(status) {
            switch (status) {
                case 'failed': return 'red';
                case 'successful': return 'green';
                case 'pending': return 'orange';
                default: return 'grey';
            }
        },

        getActivityColor(type) {
            switch (type) {
                case 'Usage': return 'orange';
                case 'Bloqué': return 'red';
                default: return 'green';
            }
        }
    },

    watch: {
        currentMode: {
            handler(newMode) {
                console.log('🔄 Changement de mode:', newMode ? 'Production' : 'Développement');
                this.getAllTransactions();
            },
            immediate: true
        },
        userList: {
            handler(newList) {
                console.log('🔄 Mise à jour de la liste des utilisateurs:', {
                    taille: newList?.length,
                    mode: this.currentMode ? 'Production' : 'Développement'
                });
                if (newList && newList.length > 0) {
                    this.getAllTransactions();
                }
            },
            immediate: true
        }
    },

    mounted() {
        console.log('🚀 Montage du composant Transaction');
        this.getAllTransactions();
    }
}
</script>
<style>
    .type{
        color:white
    }

    .label{
        font-weight: bold;
        font-size: 35px;
    }

    .usage{
        background: orange;
        height: 10px;
    }

    .blocked{
        background: red;
        height: 10px;
    }

    .free{
        height: 10px;
        background: green;
    }

    .failed-transaction {
        color: red;
    }
</style>
