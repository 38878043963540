<template>
    <v-app>
        <v-main>
            <v-container fluid>
                <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-4">
                    <v-row align="center" justify="end" class="mb-4">
                        <v-switch
                            v-model="currentMode"
                            :label="currentMode ? 'Mode Production' : 'Mode Développement'"
                            color="orange"
                            hide-details
                            @change="switchMode"
                            class="mr-4"
                        ></v-switch>
                    </v-row>
                    <div class="row mb-4">
                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>TOTAL UTILISATEURS</h3></v-card-title>
                                <v-card-subtitle>Tous les utilisateurs</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingUserCount == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ totalUserCount }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/users.svg" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>JETONS ACHETES</h3></v-card-title>
                                <v-card-subtitle>Tous les jetons</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingTokenPurchased == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">
                                                {{ totalTokenPurschasedCount }}
                                            </span>
                                        </v-col>
                                        <v-col><v-img src="../assets/coins.svg" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>UTILISATEURS PREMIUM</h3></v-card-title>
                                <v-card-subtitle>Tous les utilisateurs prémiums</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingUserPremium == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">
                                                {{ totalUserPremiumCount }}
                                            </span>
                                        </v-col>
                                        <v-col><v-img src="../assets/premium.svg" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>JETONS UTILISES</h3></v-card-title>
                                <v-card-subtitle>Total des jetons utilisés</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingTokenUsed == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ totalTokenUsedCount }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/coins_downgrade.svg" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                    </div><br>

                    <div class="row mb-4">
                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>ANDROID</h3></v-card-title>
                                <v-card-subtitle>Utilisateurs utilisant Android</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingDevices">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ androidDevices }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/android.png" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>IOS</h3></v-card-title>
                                <v-card-subtitle>Utilisateurs utilisant iOS</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingDevices">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ iosDevices }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/ios.png" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>HUAWEI</h3></v-card-title>
                                <v-card-subtitle>Utilisateurs utilisant Huawei</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingDevices">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ huaweiDevices }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/huawei.png" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>UTILISATEURS CONNECTÉS</h3></v-card-title>
                                <v-card-subtitle>Utilisateurs actuellement connectés</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingUserConnectedCount">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ userConnectedCount }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/smart-devices.png" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>CODES DE PARRAINAGE</h3></v-card-title>
                                <v-card-subtitle>Codes de parrainage utilisés</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingCodeParrainage">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ totalCodeParrainage }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/smart-devices.png" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                            <v-card class="mx-auto text-center" color="#ff7102" dark max-width="600">
                                <v-card-text>
                                    <v-sheet color="rgba(0, 0, 0, .12)"><h1 class="lastRegister">{{ lastUserRegisterList.length }}</h1></v-sheet>
                                </v-card-text>
                
                                <v-card-text>
                                    <div class="text-h4 font-weight-thin">Total des utilisateurs inscrits ces derniers 24 heures</div>
                                </v-card-text>
                
                                <v-divider></v-divider>
                
                                <v-card-actions class="justify-center"><v-btn block text @click="showLastUserSign = true"> Voir détail</v-btn></v-card-actions>
                            </v-card>
                        </div>

                        <div class="col-sm-4" v-show="showLastUserSign">
                            <v-card>
                                <v-divider></v-divider>
                                <v-card-title><h3>INSCRITS DERNIER 24H</h3></v-card-title>
                                <v-virtual-scroll v-if="lastUserRegisterList.length > 0" :bench="benched" :items="lastUserRegisterList" height="300" item-height="64">
                                    <template v-slot:default="{ item }">
                                        <v-list>
                                            <v-list-item-group active-class="orange--text">
                                                <v-list-item :key="item.node.userCode">
                                                    <v-list-item-avatar size="32">
                                                        <v-img v-if="item.node.photo == null" src="../assets/userphoto.svg"></v-img>
                                                        <v-img v-else :src="item.node.photo"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title> {{ item.node.name }} </v-list-item-title>
                                                        <v-list-item-subtitle class="text--primary">{{ item.node.firstname }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn btn x-small color="secondary" @click="checkInfoUser(item)"><v-icon small>mdi-open-in-new</v-icon> Voir détail</v-btn>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list-item-group><br>
                                        </v-list>
                                        
                                        <v-divider></v-divider>
                                    </template>
                                </v-virtual-scroll>
                                <v-card-text v-else>
                                    <v-row align="center" justify="center">
                                        <span>AUCUN UTILISATEUR INSCRIT</span>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                    </div>
                </v-sheet>

                <v-row>

                    <v-col cols="12">
                        <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-2">
                            <v-card>
                                <v-card-title><h4>LISTE DES UTILSATEURS</h4>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                                </v-card-title>

                                <v-progress-linear
                                    v-if="loading && loadingProgress > 0"
                                    :value="loadingProgress"
                                    color="orange"
                                    height="2"
                                >
                                    <template v-slot:default>
                                        <strong>{{ Math.ceil(loadingProgress) }}%</strong>
                                    </template>
                                </v-progress-linear>

                                <v-card-text>
                                    <v-data-table 
                                        :headers="headers" 
                                        :items="userList" 
                                        :single-select="singleSelect" 
                                        show-select 
                                        :search="search" 
                                        :loading="loading"
                                        @click:row="handleRowClick"
                                        item-key="node.userCode" 
                                        v-model="selected"
                                        class="cursor-pointer"
                                    >
                                        <template v-slot:top>
                                            <v-row>
                                                <v-btn 
                                                    small 
                                                    color="secondary" 
                                                    class="ml-2" 
                                                    @click="openDialog()"
                                                >
                                                    <v-icon>mdi-bell</v-icon>
                                                    ALERTER LES COMPTES
                                                </v-btn>
                                                <v-btn 
                                                    small 
                                                    class="ml-2" 
                                                    color="success"
                                                >
                                                    <export-excel 
                                                        class="btn" 
                                                        :data="userList" 
                                                        :fields="json_fields" 
                                                        worksheet="My Worksheet" 
                                                        name="users.xls"
                                                    >
                                                        <v-icon color="white">mdi-download</v-icon>
                                                        <small class="font-weight-bold text-white">EXPORTER LES DONNEES</small>
                                                    </export-excel>
                                                </v-btn>
                                                &nbsp;&nbsp;
                                                <span class="font-weight-bold mt-1">
                                                    Selectionné: {{ selected.length }}
                                                </span>
                                            </v-row>
                                            <br><br>
                                        </template>
                                        
                                        <template v-slot:[`item.actions`]="{item}">
                                            <v-icon 
                                                title="Voir détail" 
                                                color="blue" 
                                                class="mr-3" 
                                                small 
                                                @click.stop="checkInfoUser(item)"
                                            >
                                                mdi-eye
                                            </v-icon>
                                            <v-icon 
                                                title="Supprimer l'utilisateur" 
                                                color="red" 
                                                class="mr-3" 
                                                small 
                                                @click.stop="deleteUserAccount(item.node.email)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                    </v-data-table>
                                </v-card-text>

                                <v-dialog v-model="dialogUser" width="600" rounded="lg">
                                    <v-card>
                                        <v-toolbar elevation="4">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="orange" @click="dialogNotificationUser = true"><span class="font-weight-bold">Alerter le compte</span></v-btn>
                                        </v-toolbar><br>

                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-avatar class="ms-2" color="white" size="120" variant="flat">
                                                        <v-img v-if="userInfo.photo == null" src="../assets/userphoto.svg"></v-img>
                                                        <v-img v-else :src="userInfo.photo"></v-img>
                                                    </v-avatar>
                                                </v-col>
                                                <v-divider vertical inset></v-divider>

                                                <v-col>
                                                    <v-row>
                                                        <v-col><span class="font-weight-bold">Nom:</span>&nbsp;&nbsp;&nbsp;&nbsp;<small class="text">{{ userInfo.name }}</small></v-col>
                                                        <v-col><span class="font-weight-bold">Prenoms:</span>&nbsp;&nbsp;&nbsp;&nbsp;<small class="text">{{ userInfo.firstname }}</small></v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col><span class="font-weight-bold">Email:</span>&nbsp;&nbsp;&nbsp;&nbsp;<small class="text">{{ userInfo.email }}</small></v-col>
                                                        <v-col><span class="font-weight-bold">Tél:</span>&nbsp;&nbsp;&nbsp;&nbsp;<small class="text">{{ userInfo.phoneNumber }}</small></v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col><span class="font-weight-bold">Jetons acheté:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <small v-if="isLoadingTokenPurchasedByUser == true"><v-progress-circular indeterminate></v-progress-circular></small>
                                                            <small v-else class="text">{{ tokenPurschasedByUser }}</small>
                                                        </v-col>
                                                        <v-col><span class="font-weight-bold">Jetons Utilisés:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <small v-if="isLoadingTokenUsedByUser == true">
                                                                <v-progress-circular indeterminate></v-progress-circular>
                                                            </small>
                                                            <small v-else class="text">{{ tokenUsedByUser }}</small>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="green-darken-1" variant="text" @click="dialogUser = false">Retour</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                            </v-card>
                        </v-sheet>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-2">
                            <v-card>
                                <v-card-title><h4>Listes des Utilisateurs Prémiums</h4>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchNotif" append-icon="mdi-magnify" label="Search"
                                    single-line hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table :headers="headersPremium" :items="totalUserPremiumList" :search="searchNotif" :loading="loading">
                                </v-data-table>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-dialog v-model="dialogNotificationUser" transition="dialog-top-transition" width="500">
                    <v-card>
                        <v-card-title>Notification à envoyer</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col><v-textarea color="orange" v-model="notificationAlertUser" label="Votre message" dense></v-textarea></v-col>
                            </v-row>
                            <v-row>
                                <v-col><v-text-field color="orange" v-model="linkToNotifyUser" label="Votre lien" placeholder="Domaine uniquement" dense></v-text-field></v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn variant="text" color="orange" @click="alertAccount(notificationAlertUser , linkToNotifyUser , userInfo.userCode)">Envoyer</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                <v-dialog v-model="detailUserDialog" transition="dialog-top-transition" width="1000">
                    <DialogUserVue 
                        :user="userInfo"
                        @close-dialog="detailUserDialog = false"
                    />
                </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <v-dialog v-model="sendEmailDynamicDialog" transition="dialog-top-transition" width="600">
                    <v-card>
                        <v-card-title>Envoi d'e-mail
                            <v-spacer></v-spacer>
                            <v-btn icon @click="addNewParagraph"><v-icon color="primary">mdi-plus</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col><v-text-field color="orange" v-model="objectDynamic" label="Objet" dense></v-text-field></v-col>
                            </v-row>
                            <v-row>
                                <v-col><v-text-field color="orange" v-model="titleDynamic" label="Titre" dense></v-text-field></v-col>
                            </v-row>
                            <v-row v-for="(paragraph , p) in inputs" :key="p">
                                <v-col cols="10"><v-text-field color="orange" v-model="paragraph.message" :label="'Paragraph n°' + (p + 1)"></v-text-field></v-col>
                                <v-col cols="2"><v-btn icon @click="deleteParagraph(p)"><v-icon color="red">mdi-close</v-icon></v-btn></v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn variant="text" color="orange" @click="sendDynamicEmailToUser(userInfo.email , objectDynamic , titleDynamic , inputs)">Envoyer le courriel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                <v-dialog v-model="dialogNotificationGroupUser" transition="dialog-top-transition" width="500">
                    <v-card>
                        <v-card-title>NOTIFICATION GENERALE</v-card-title>
                        <v-card-text>
                            <v-container>
                                <form>
                                    <div class="form-group">
                                        <label>Type de message:</label>
                                        <v-text-field :error-messages="typeMessageError" dense outlined v-model.trim="$v.typeMessage.$model"></v-text-field>
                                        <small id="emailHelp" class="form-text text-muted">C'est quoi votre message ? </small>
                                    </div>
                                    <div class="form-group">
                                        <label>Votre Message</label>
                                        <v-textarea :error-messages="notificationAlertUserGroupError" outlined dense v-model.trim="$v.notificationAlertUserGroup.$model"></v-textarea>
                                        <small class="form-text text-muted">*Champs obligatoire.</small>
                                    </div>
                                    <div class="form-group">
                                        <label>Champs URL:</label>
                                        <v-text-field :error-messages="linkUserGroupError" v-model.trim="$v.linkToNotifyUserGroup.$model" dense outlined type="text" placeholder="https://"></v-text-field>
                                    </div>
                                    <small id="emailHelp" class="form-text text-muted">NB : (*) devant un champs signifie qu'il est obligatoire.</small>
                                </form>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" small @click="dialogNotificationGroupUser = false"><span>Retour</span></v-btn>
                            <v-btn color="orange" :disabled="btnSendNotificationGroup==true" small @click="checkingAndSendingNotification(notificationAlertUserGroup , typeMessage , linkToNotifyUserGroup)"><span class="text-white">Envoyer la notification</span></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="depositToken" transition="dialog-top-transition" width="500">
                    <v-card>
                        <v-card-title><span>RECHARGER UN UTILISATEUR</span></v-card-title>
                        <v-card-text>
                            <v-container>
                                <form>
                                    <div class="form-group">
                                        <label>Réference du rechargement:</label>
                                        <v-select :items="labelTransaction" item-text="label" item-value="label" type="text" :error-messages="labelDepositError" dense outlined v-model.trim="$v.labelDeposit.$model"></v-select>
                                        <small id="emailHelp" class="form-text text-muted">Saisissez un libellé pour le rechargement? </small>
                                    </div>
                                    <div class="form-group">
                                        <label>Nombre de jetons:</label>
                                        <v-select :items="tokenItems" item-text="amount" item-value="amount" type="number" min="0" :error-messages="tokenToSendError" dense outlined v-model.number="$v.tokenToSend.$model"></v-select>
                                        <small id="emailHelp" class="form-text text-muted">Combien de <strong>ARI</strong> voulez vous envoyer ? </small>
                                    </div>
                                    <small id="emailHelp" class="form-text text-muted">NB : (*) devant un champs signifie qu'il est obligatoire.</small>
                                </form>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn variant="text" color="orange" @click="sendTokenToUser(tokenToSend,labelDeposit,userInfo.userCode)">RECHARGER</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </v-container>
        </v-main>

        <v-snackbar
            v-model="alerts.error.show"
            color="error"
            timeout="4000"
        >
            {{ alerts.error.message }}
        </v-snackbar>

        <v-overlay :value="overlay">
            <v-progress-circular color="orange" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script>
import axios from 'axios';
import { numeric, required } from 'vuelidate/lib/validators';
import { getCurrentUrl, userConnect } from '../Config/globalConst';
import DialogUserVue from './dialog_components/DialogUser.vue';
import JsonExcel from 'vue-json-excel';
import { EventBus } from '../eventBus';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name:"TableauBord",
    components:{
        DialogUserVue,
        'export-excel': JsonExcel
    },
    data() {
        return{
            btnSendNotificationGroup:null,
            
            labelDeposit : null,
            tokenToSend : null,
            userCode : null ,

            benched : 0 ,
            depositToken: false ,
            detailUserDialog : false,

            labelTransaction : [
                { label: "Achat de jetons" },
                { label : "Bonus "}
            ],

            tokenItems : [
                { amount : 3},
                { amount : 5},
                { amount : 10}
            ],

            json_fields : {
                'Nom' : 'node.name',
                'Prenom(s)': 'node.firstname',
                'Date d\'inscription': 'node.creationDate',
                'E-mail': 'node.email',
                'Telephone': 'node.phoneNumber',
            },

            overlay: false,
            showLastUserSign : false ,

            errorMsg : null,
            successMsg : null,
            warningMsg : null,
            loading : false,
            loadingNotif: false,
            loadingJetons:false,
            dialogUser : false,

            drawer: false,
            group: null,

            singleSelect: false,
            selected: [],

            dialogNotification : false ,
            dialogNotificationUser : false ,
            dialogNotificationGroupUser : false ,
            sendEmailDynamicDialog: false,

            notificationAlert : null,
            linkToNotify : null,

            notificationAlertUser : null,
            linkToNotifyUser : null,

            notificationAlertUserGroup : null,
            linkToNotifyUserGroup : null,
            typeMessage : null ,

            objectDynamic:null,
            titleDynamic : null,
            paragrapheDynamicList : [],

            inputs : [{ message : null}],

            userConnectedCount : 0,
            
            headers:[
                { text: 'Nom', value: 'node.name' },
                { text: 'Prénom(s)', value: 'node.firstname' },
                { text: 'Email', value: 'node.email' },
                { text: 'Téléphone', value: 'node.phoneNumber' },
                { text: 'Date d\'inscription', value: 'node.creationDate' },
                { text: 'Dernière connexion', value: 'node.lastLogin.login' },
                { text: 'Dernière déconnexion', value: 'node.lastLogin.logout' },
                { text: 'Dernière modification CV', value: 'node.lastCvUpdate' },
                { text: 'Actions', value: 'actions', sortable: false }
            ],
            
            search: '',

            headersPremium:[
                { text: 'Noms', value: 'name' },
                { text: 'Prenoms', value: 'firstname' },
                { text: 'N° de Tél', value: 'phoneNumber' },
                { text: "E-mail", value: 'email' },
                { text: "Fin d'abonnement",value: 'subscriptionExpirationDate' },
            ],
            searchNotif: '',

            headersJetons:[
                { text: 'Nom', value: 'node.user.name' },
                { text: 'Prenoms', value: 'node.user.firstname' },
                { text: 'email', value: 'node.user.email' },
                { text: 'Nbre de jetons', value: 'node.amount' },
                
            ],

            searchJetons: '',

            totalUserCount : null ,
            totalTokenPurschasedCount : null,
            totalTokenUsedCount : null,
            totalUserPremiumCount: null,
            totalCodeParrainage: null,
            totalUserPremiumList : [],
            userList : [],
            notificationList :[],
            notificationToShow:[],

            jetonList:[],

            lastUserRegisterList : [],
            emailAdmin : null,

            userInfo:{
                userCode : null,
                creationDate: null,
                name: null,
                firstname: null,
                photo:null,
                email:null,
                phoneNumber:null,
                sex:null,
                birthDate:null,
            },

            tokenUsedByUser: null,
            tokenPurschasedByUser: null,
            isLoadingUserPremium: false,
            isLoadingCodeParrainage: false,
            isLoadingUserPremiumList : false,
            allTokensPurchasedByUser : null ,
            isLoadingUserCount : false,
            isLoadingTokenPurchased : false,
            isLoadingTokenUsed : false,
            isLoadingTokenPurchasedByUser:false,
            isLoadingTokenUsedByUser:false,
            isLoadingUserConnectedCount:false,
            alerts: {
                error: {
                    show: false,
                    message: ''
                },
                success: {
                    show: false,
                    message: ''
                }
            },

            isLoadingDevices: false,
            androidDevices: 0,
            iosDevices: 0,
            huaweiDevices: 0,

            premiumUsersCache: null,
            premiumUsersCacheExpiry: null,
            cacheDuration: 5 * 60 * 1000, // 5 minutes en millisecondes

            userListCache: null,
            userListCacheExpiry: null,
            userCacheDuration: 5 * 60 * 1000, // 5 minutes en millisecondes

            cacheStore: {
                basicUserData: new Map(),
                lastFetch: {
                    basic: null
                },
                expiryTime: {
                    basic: 5 * 60 * 1000    // 5 minutes pour les données de base
                }
            },
            loadingProgress: 0,
            batchSize: 50,
            tempUserList: [], // Pour stocker temporairement les utilisateurs pendant le chargement
        }
    },

    validations:{

        notificationAlert :{
            required
        },

        linkToNotify :{
            required
        },

        notificationAlertUserGroup : {
            required
        },

        linkToNotifyUserGroup :{
            required
        },
            
        typeMessage : {
            required
        },

        tokenToSend:{
            required , numeric
        },

        labelDeposit:{
            required
        }

    },

    computed:{
        ...mapState({
            currentMode: state => state.environment.isProduction,
            cachedUsers: state => state.users.userCache.data,
            isLoading: state => state.users.isLoading
        }),
        ...mapGetters('users', ['isCacheValid']),
        notificationError(){
            if (!this.$v.notificationAlert.$dirty) return [];
            const errors =  [] ;
            !this.$v.notificationAlert.required && errors.push("Le champs notification est obligatoire");
            return errors ;
        },

        linkError(){
            if (!this.$v.linkToNotify.$dirty) return [];
            const errors =  [] ;
            !this.$v.linkToNotify.required && errors.push("Entrer un lien");
            return errors ;
        },
        typeMessageError(){
            if (!this.$v.typeMessage.$dirty) return [];
            const errors =  [] ;
            !this.$v.typeMessage.required && errors.push("Quel est le type de votre message ?");
            return errors ;
        },

        notificationAlertUserGroupError(){
            if (!this.$v.notificationAlertUserGroup.$dirty) return [];
            const errors =  [] ;
            !this.$v.notificationAlertUserGroup.required && errors.push("Le message ne peut être vide !");
            return errors ;
        },

        linkUserGroupError(){
            if (!this.$v.linkToNotifyUserGroup.$dirty) return [];
            const errors =  [] ;
            !this.$v.linkToNotifyUserGroup.required && errors.push("Le lien est obligatoire ");
            return errors ;
        },

        labelDepositError(){
            if (!this.$v.labelDeposit.$dirty) return [];
            const errors =  [] ;
            !this.$v.labelDeposit.required && errors.push("Choisissez une reference !");
            return errors ;
        },

        tokenToSendError(){
            if (!this.$v.tokenToSend.$dirty) return [];
            const errors =  [] ;
            !this.$v.tokenToSend.required && errors.push(" Combien de jetons voulez-vous envoyer ?");
            !this.$v.tokenToSend.numeric && errors.push(" Mauvaise saisie. le token doit être un nombre !");
            return errors ;
        },
    },

    methods:{
        ...mapActions(['switchEnvironmentMode']),

        async checkInfoUser(item) {
            if (!item || !item.node) {
                console.error("Invalid item data");
                return;
            }

            console.log('Données de l\'utilisateur avant assignation:', {
                numTokens: item.node.numTokens,
                tokensIsBlocked: item.node.tokensIsBlocked,
                tokenExpirationDate: item.node.tokenExpirationDate
            });
            
            this.userInfo = {
                userCode: item.node.userCode || '',
                creationDate: item.node.creationDate || '',
                name: item.node.name || '',
                firstname: item.node.firstname || '',
                photo: item.node.photo || null,
                email: item.node.email || '',
                phoneNumber: item.node.phoneNumber || '',
                sex: item.node.sex || '',
                birthDate: item.node.birthDate || '',
                numTokens: item.node.numTokens,
                tokensIsBlocked: item.node.tokensIsBlocked,
                tokenExpirationDate: item.node.tokenExpirationDate
            };
            
            console.log('Données de l\'utilisateur après assignation:', {
                numTokens: this.userInfo.numTokens,
                tokensIsBlocked: this.userInfo.tokensIsBlocked,
                tokenExpirationDate: this.userInfo.tokenExpirationDate
            });
            
            this.detailUserDialog = true;

            if (item.node.userCode) {
                await Promise.all([
                    this.getTotalTokenPurschasedByUser(item.node.userCode),
                    this.getTotalTokenUsedByUser(item.node.userCode)
                ]);
            }
        },

        openDialogToken(user){
            this.numTokens = user.node.numTokens
            this.tokenExpirationDate = user.node.tokenExpirationDate
            this.tokensIsBlocked = user.node.tokensIsBlocked
            this.depositToken = true;
            this.userInfo.firstname = user.node.firstname ;
            this.userInfo.creationDate = user.node.creationDate ;
            this.userInfo.name = user.node.name;
            this.userInfo.userCode = user.node.userCode;
        },

        closeDialogToken(){
            this.depositToken = false
        },

        async sendTokenToUser(amount , label , userCode){
            this.$v.$touch();
            if(this.$v.labelDeposit.$invalid){
                this.errorMsg = "Selectionnez un label"
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }else if(this.$v.tokenToSend.$invalid){
                this.errorMsg = "Le champ de jetons est obligatoire"
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000)
            }else{
                this.$swal({
                    title: "Envoyer les jetons?", text: "Êtes vous sure ? Attention cette action est irréversible",
                    type: "Warning", showCancelButton: true,
                    confirmButtonColor: "#c0392b",confirmButtonText: "Oui, supprimer!"
                }).then((result) => {
                    this.overlay = true;
                    if (result.value) {
                        let graphqlQuery = {
                            query : `mutation{
                                payTokens(amount:${amount}, label:"${label}", userCode:"${userCode}"){
                                    success
                                }
                            }`
                        }
                        axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                            if(response.data.data.payTokens.success === true){
                                this.$swal.fire('Rechargement Reussi' , "L' utilisateur a été rechargé"  , 'success')
                            }else{
                                this.$swal.fire('Rechargement echouée' , "Problème Interne" , 'error')
                            }
                        }).catch((e)=>{
                            this.$swal.fire('Rechargement echouée' , e , 'error')
                        }).finally(()=>{
                            this.overlay = false
                        })
                    }
                })
                this.closeDialogToken()
            }
        },

        async sendDynamicEmailToUser(usermail , sujet , titre){
            this.sendEmailDynamicDialog = false
            this.overlay = true
            this.inputs.forEach(element => {
                this.paragrapheDynamicList.push(element.message);
            });
            let graphqlQuery = {
                query : `mutation{
                    sendDynamicMail(email: "${usermail}" , sujet:"${sujet}" , titre:"${titre}" , paragraphes:${JSON.stringify(this.paragrapheDynamicList)}){
                        success
                    }
                }`
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{ 
                if (response.data.data.sendDynamicMail.success === true) {
                this.$swal.fire("Envoi de mail", "E-mail envoyé avec succes","success");
                }
            }).catch((e)=>{
                this.$swal.fire("Echec de l'envoi", e ,"error");
            }).finally(()=>{
                this.overlay = false ;
            })
        },

        sendEmailDialog(usermail){
            this.sendEmailDynamicDialog = true;
            this.userInfo.email = usermail
        },
    
        addNewParagraph(){
            this.inputs.push({message: null,})
        },

        deleteParagraph(position){
            this.inputs.splice(position , 1);
        },

        async getTotalUserCount(){
            this.isLoadingUserCount = true
            let graphqlQuery = {
                "query" : "query {userCount}"
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalUserCount = response.data.data.userCount;
                }
                else{
                    this.errorMsg = "Erreur serveur , actualisez"
                    this.overlay = true;
                    setTimeout(() => {
                        this.overlay = false;
                    }, 4000);
                    this.totalUserCount = 0;
                }
            }).catch((e)=>{
                this.errorMsg = e;
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }).finally(()=>{
                this.isLoadingUserCount = false
            })
        },
        
        async deleteUserAccount(email) {
            try {
                this.dialogUser = false;
                this.detailUserDialog = false;
                
                const result = await this.$swal({
                    title: 'Supprimer cet utilisateur?',
                    text: 'Êtes vous sûr ? Cette action est irréversible',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#c0392b',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Oui, supprimer!',
                    cancelButtonText: 'Annuler'
                });

                if (result.isConfirmed) {
                    this.overlay = true;
                    const graphqlQuery = {
                        query: `mutation {
                            deleteUserByEmail(email: "${email}") {
                                success
                            }
                        }`
                    };

                    const response = await axios.post(getCurrentUrl(), graphqlQuery);
                    
                    if (response.data.data.deleteUserByEmail.success) {
                        await this.$swal.fire(
                            'Supprimé!',
                            'L\'utilisateur a été supprimé avec succès.',
                            'success'
                        );
                        await this.getUsersList();
                        await this.getTotalUserCount();
                    } else {
                        throw new Error('La suppression a échoué');
                    }
                }
            } catch (error) {
                this.$swal.fire(
                    'Erreur',
                    'Une erreur est survenue lors de la suppression',
                    'error'
                );
                console.error('Erreur lors de la suppression:', error);
            } finally {
                this.overlay = false;
            }
        },

        returnDateFormatted(dateFormatted){
            let date = new Date(dateFormatted);
            let jour = date.getDate();
            let mois = date.getMonth() + 1 ;
            let annee = date.getFullYear();
            jour = String(jour).padStart(2 , '0');
            mois = String(mois).padStart(2 , '0');
            let dateFormat = `${annee}-${mois}-${jour}`;
            return dateFormat;
        },

        returnHoursMinutes(dateFormatted){
            let date = new Date(dateFormatted);
            let heure = date.getHours();
            let minute = date.getMinutes();
            heure = String(heure).padStart(2, '0');
            minute = String(minute).padStart(2, '0');
            let hoursFormat = `${heure}-${minute}`;
            return hoursFormat;
        },

        async getTotalTokenPurchased(){
            this.isLoadingTokenPurchased = true
            let graphqlQuery = {
                "query" : "query {totalTokensPurchased}"
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalTokenPurschasedCount = response.data.data.totalTokensPurchased;
                }
                else{
                    this.errorMsg = "Erreur serveur , actualisez"
                    this.overlay = true;
                    setTimeout(() => {
                        this.overlay = false;
                    }, 4000);
                    this.totalTokenPurschasedCount = 0;
                }
            }).catch((e)=>{
                this.errorMsg = e;
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }).finally(()=>{
                this.isLoadingTokenPurchased = false
            })
        },

        async userPremium(){
            this.isLoadingUserPremium = true;
            let graphqlQuery = {
                "query" : "query{userPremiumCount}"
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalUserPremiumCount = response.data.data.userPremiumCount;
                    console.log('User Premium Count : ', response.data.data.userPremiumCount);
                }
                else{
                    console.log('Error User Premium Count : ', response.data.data.errors);
                    this.errorMsg = response.data.data.errors;
                    this.overlay = true;
                    setTimeout(() => {
                        this.overlay = false;
                    }, 4000);
                    this.isLoadingUserPremium = false;
                }
            }).catch((e)=>{
                console.log('Error User Premium Count : ', e);
                this.errorMsg = e;
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }).finally(()=>{
                this.isLoadingUserPremium = false
            })
        },

        async userPremiumList() {
            this.loading = true;
            try {
                if (this.premiumUsersCache && this.premiumUsersCacheExpiry && Date.now() < this.premiumUsersCacheExpiry) {
                    this.totalUserPremiumList = this.premiumUsersCache;
                    this.loading = false;
                    return;
                }

                let graphqlQuery = {
                    "query": "query{userPremium{name,phoneNumber,firstname,subscriptionExpirationDate,email}}"
                }

                const response = await axios.post(getCurrentUrl(), graphqlQuery);
                
                if (response.status === 200) {
                    const formattedUsers = response.data.data.userPremium.map(user => ({
                        ...user,
                        subscriptionExpirationDate: user.subscriptionExpirationDate ? 
                            `${this.returnDateFormatted(user.subscriptionExpirationDate)} ${this.returnHoursMinutes(user.subscriptionExpirationDate)}` :
                            'Non défini'
                    }));

                    this.premiumUsersCache = formattedUsers;
                    this.premiumUsersCacheExpiry = Date.now() + this.cacheDuration;
                    this.totalUserPremiumList = formattedUsers;
                } else {
                    console.log('Error User Premium List : ', response.data.data.errors);
                    this.errorMsg = response.data.data.errors;
                    this.overlay = true;
                    setTimeout(() => {
                        this.overlay = false;
                    }, 4000);
                }
            } catch (e) {
                console.log('Error User Premium List : ', e);
                this.errorMsg = e;
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            } finally {
                this.loading = false;
            }
        },

        refreshPremiumUsers() {
            this.premiumUsersCache = null;
            this.premiumUsersCacheExpiry = null;
            return this.userPremiumList();
        },

        async getTotalCodeParrainage(){
            this.isLoadingCodeParrainage = true;
            let graphqlQuery = {
                "query" : "query{getTotalReferralCodesUsed{totalUsedCodes}}"
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalCodeParrainage = response.data.data.getTotalReferralCodesUsed.totalUsedCodes;
                    console.log('Total Code Parrainage : ',response.data.data.getTotalReferralCodesUsed.totalUsedCodes);
                }

                else {
                    console.log('Error Total Code Parrainage : ', response.data.data.getTotalReferralCodesUsed);
                    this.errorMsg = response.data.data.error
                    this.overlay = true;
                    setTimeout(() => {
                        this.overlay = false;
                    }, 4000);
                    this.isLoadingCodeParrainage = false
                }
                
            }).catch((e) =>{
                console.log('Error Code Parrainage : ', e);
                this.errorMsg = e;
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }).finally(()=>{
                this.isLoadingCodeParrainage = false
            })

        },
        async getTotalTokenUsed(){
            this.isLoadingTokenUsed = true;
            let graphqlQuery = {
                "query" : "query{totalTokenUsed}"
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalTokenUsedCount = response.data.data.totalTokenUsed;
                }
                else{
                    this.errorMsg = "Erreur de chargement..."
                    this.overlay = true;
                    setTimeout(() => {
                        this.overlay = false;
                    }, 4000);
                    this.totalTokenUsedCount = 0;
                }
            }).catch((e)=>{
                this.errorMsg = e;
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }).finally(()=>{
                this.isLoadingTokenUsed = false;
            })
        },

        isCacheValid(type) {
            const lastFetch = this.cacheStore.lastFetch[type];
            const expiryTime = this.cacheStore.expiryTime[type];
            return lastFetch && (Date.now() - lastFetch) < expiryTime;
        },

        updateCache(type, data) {
            if (type === 'basic') {
                data.forEach(user => {
                    this.cacheStore.basicUserData.set(user.node.userCode, {
                        name: user.node.name,
                        email: user.node.email,
                        firstname: user.node.firstname,
                        creationDate: user.node.creationDate,
                        phoneNumber: user.node.phoneNumber,
                        photo: user.node.photo
                    });
                });
            }
            this.cacheStore.lastFetch[type] = Date.now();
        },

        displayCachedBasicData() {
            this.userList = Array.from(this.cacheStore.basicUserData.entries()).map(([userCode, userData]) => ({
                node: {
                    userCode,
                    ...userData,
                    creationDate: this.returnDateFormatted(userData.creationDate)
                }
            }));
        },

        async getUsersList() {
            if (this.loading) return;
            
            console.log('🚀 Début du chargement optimisé des utilisateurs');
            this.loading = true;
            this.loadingProgress = 0;
            
            try {
                // Vérifier le cache
                if (this.isCacheValid('users')) {
                    console.log('📦 Utilisation du cache pour les données');
                    this.userList = Array.from(this.cachedUsers.values()).map(user => ({
                        node: {
                            ...user.node,
                            creationDate: this.returnDateFormatted(user.node.creationDate),
                            lastCvUpdate: this.formatCvUpdate(user.node.cvInfosSet),
                            lastLogin: this.formatLastLogin(user.node.loginhistorySet)
                        }
                    }));
                    return;
                }

                // Si pas de cache valide, charger les données
                await this.loadUsersBatch();
            } catch (error) {
                console.error('❌ Erreur:', error);
                this.showAlert('error', 'Erreur lors du chargement des données');
            } finally {
                this.loading = false;
            }
        },

        async loadUsersBatch() {
            console.log('🚀 Début du chargement des utilisateurs');
            let hasNextPage = true;
            let cursor = null;
            let totalProcessed = 0;
            this.tempUserList = []; // Réinitialiser la liste temporaire
            this.userList = []; // Réinitialiser la liste principale
            
            const loadBatch = async (afterCursor) => {
                console.log('📦 Chargement du lot suivant', { afterCursor });
                const query = {
                    query: `
                        query($first: Int, $after: String) {
                            allUserProfil(first: $first, after: $after) {
                                edges {
                                    cursor
                                    node {
                                        userCode
                                        name
                                        firstname
                                        email
                                        creationDate
                                        phoneNumber
                                        photo
                                        numTokens
                                        tokensIsBlocked
                                        tokenExpirationDate
                                        cvInfosSet(last: 1) {
                                            edges {
                                                node {
                                                    updateDate
                                                }
                                            }
                                        }
                                        loginhistorySet(last: 1) {
                                            edges {
                                                node {
                                                    loginDatetime
                                                    logoutDatetime
                                                }
                                            }
                                        }
                                    }
                                }
                                pageInfo {
                                    hasNextPage
                                    endCursor
                                }
                            }
                        }
                    `,
                    variables: {
                        first: this.batchSize,
                        after: afterCursor
                    }
                };

                return axios.post(getCurrentUrl(), query);
            };

            try {
                while (hasNextPage) {
                    const response = await loadBatch(cursor);
                    const batch = response.data?.data?.allUserProfil;
                    
                    if (!batch?.edges) {
                        console.error('❌ Format de réponse invalide');
                        throw new Error('Format de réponse invalide');
                    }

                    console.log('📥 Lot reçu:', {
                        taille: batch.edges.length,
                        total: totalProcessed + batch.edges.length,
                        premierUtilisateur: batch.edges[0]?.node ? {
                            email: batch.edges[0].node.email,
                            numTokens: batch.edges[0].node.numTokens,
                            tokensIsBlocked: batch.edges[0].node.tokensIsBlocked,
                            tokenExpirationDate: batch.edges[0].node.tokenExpirationDate
                        } : null
                    });

                    const sortedBatch = batch.edges.sort((a, b) => {
                        return new Date(b.node.creationDate) - new Date(a.node.creationDate);
                    });

                    // Mettre à jour la liste temporaire et principale
                    this.tempUserList = [...this.tempUserList, ...sortedBatch];
                    this.userList = this.tempUserList.map(user => {
                        console.log('👤 Traitement utilisateur:', {
                            email: user.node.email,
                            numTokens: user.node.numTokens,
                            tokensIsBlocked: user.node.tokensIsBlocked,
                            tokenExpirationDate: user.node.tokenExpirationDate
                        });
                        
                        return {
                            node: {
                                ...user.node,
                                creationDate: this.returnDateFormatted(user.node.creationDate),
                                lastCvUpdate: this.formatCvUpdate(user.node.cvInfosSet),
                                lastLogin: this.formatLastLogin(user.node.loginhistorySet)
                            }
                        };
                    }).sort((a, b) => new Date(b.node.creationDate) - new Date(a.node.creationDate));

                    // Mettre à jour le store avec la liste complète des utilisateurs
                    console.log('💾 Mise à jour du store avec', this.userList.length, 'utilisateurs');
                    console.log('🔍 Échantillon de données:', this.userList.slice(0, 3).map(user => ({
                        email: user.node.email,
                        numTokens: user.node.numTokens,
                        tokensIsBlocked: user.node.tokensIsBlocked,
                        tokenExpirationDate: user.node.tokenExpirationDate
                    })));
                    
                    await this.$store.dispatch('users/updateUserList', this.userList);

                    totalProcessed += batch.edges.length;
                    this.loadingProgress = Math.min(100, (totalProcessed * 100) / Math.max(totalProcessed, this.batchSize));
                    
                    hasNextPage = batch.pageInfo.hasNextPage;
                    cursor = batch.pageInfo.endCursor;

                    await new Promise(resolve => setTimeout(resolve, 100));
                }

                console.log(`✅ Chargement terminé : ${totalProcessed} utilisateurs chargés`);
                console.log('📊 Statistiques finales:', {
                    totalUtilisateurs: this.userList.length,
                    utilisateursAvecTokens: this.userList.filter(u => u.node.numTokens > 0).length,
                    utilisateursTokensBloques: this.userList.filter(u => u.node.tokensIsBlocked).length
                });

            } catch (error) {
                console.error('❌ Erreur lors du chargement par lots:', error);
                throw error;
            }
        },

        processUserBatch(users) {
            users.forEach(user => {
                const formattedUser = {
                    node: {
                        ...user.node,
                        creationDate: this.returnDateFormatted(user.node.creationDate),
                        lastCvUpdate: this.formatCvUpdate(user.node.cvInfosSet),
                        lastLogin: this.formatLastLogin(user.node.loginhistorySet)
                    }
                };

                const existingIndex = this.userList.findIndex(u => u.node.userCode === user.node.userCode);
                if (existingIndex >= 0) {
                    this.userList.splice(existingIndex, 1, formattedUser);
                } else {
                    this.userList.push(formattedUser);
                }
            });
        },

        formatCvUpdate(cvInfosSet) {
            if (cvInfosSet?.edges?.length > 0) {
                const lastUpdate = cvInfosSet.edges[0].node;
                return lastUpdate.updateDate ? 
                    `${this.returnDateFormatted(lastUpdate.updateDate)} ${this.returnHoursMinutes(lastUpdate.updateDate)}` : 
                    'Aucune modification';
            }
            return 'Aucune modification';
        },

        logout(){
            this.$router.replace({name:"home"})
        },

        async createNotification(description, link , userCode){
            let graphqlQuery = {
                query : `mutation{
                    sendStaticNotification(description: "${description}",  link: "https://${link}" , userCode: "${userCode}"){
                        success
                    }
                }`
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                if(response.data.data.sendStaticNotification.success === true){
                this.$swal.fire('Notification enregistré' , 'Opération effectuée avec succes' , 'success')
                }else{
                this.$swal.fire('Echec de l\'enregistrement de notification' , '' , 'error')
                }
            }).catch((e)=>{
                this.$swal.fire('Echec de l\'enregistrement de notification' , e , 'error')
            })
        },

        async sendNotificationToUsers(description, link) {
  console.log('Début de la méthode sendNotificationToUsers');
  this.$v.$touch();
  console.log('Validation des données :', this.$v);

  if (this.$v.notificationAlert.$invalid || this.$v.linkToNotify.$invalid) {
    console.log('Les champs obligatoires sont invalides');
    this.errorMsg = "Certains champs omis sont requis";
    this.overlay = true;
    setTimeout(() => {
      this.overlay = false;
    }, 4000);
  } else {
    console.log('Tous les champs validés');
    let graphqlQuery = {
      query: `mutation{
        sendNotificationToAllUsers(cat: "Systeme" , body: "${description}", title: "Mise à jour" , link: "${link}"){
          success
        }
      }`
    };
    console.log('Requête GraphQL:', graphqlQuery.query);
     
    try {
      await axios.post(getCurrentUrl(), graphqlQuery).then((response) => {
        console.log('Réponse reçue:', response.data);
        if (response.status === 200 && response.data.data.sendNotificationToAllUsers.success === true) {
          console.log('Notification envoyée avec succès');
          this.createNotification(description, link);
          this.close();
        } else {
          console.log('Erreur lors de l\'envoi de la notification');
          throw new Error('Échec de l\'envoi de la notification');
        }
      }).catch((error) => {
        console.error('Erreur lors de l\'envoi de la notification:', error);
        this.$swal.fire('Notification non envoyée', error.message || 'Une erreur s\'est produite', 'error');
      });
    } catch (error) {
      console.error('Erreur générale:', error);
      this.$swal.fire('Erreur', error.message || 'Une erreur s\'est produite lors de l\'envoi de la notification', 'error');
    }
  }
        },

        async sendMailToAllUsersWithoutInformation(){
            this.overlay = true
            let graphqlQuery = {
                query: `mutation{ sendEmailToUsersNull{
                    success
                    }
                }`
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                if (response.data.data.sendEmailToUsersNull.success === true) {
                    this.$swal.fire('Mail envoyée' , "Les utilisateurs ont bien recu leur mail" , 'success')
                }
            }).catch((e)=>{
                this.$swal.fire('Mail non envoyee' , e , 'error')
            }).finally(()=>{
                this.overlay = false
            })
        },

        openDialog(){
            if(this.selected.length == 0){
                this.$swal.fire('Aucun compte selectionné' , '' , 'error')
            }else{
                this.dialogNotificationGroupUser = true
            }
        },
        
        checkingAndSendingNotification(description, title,  link){
            this.$v.$touch()
            if(this.$v.notificationAlertUserGroup.$invalid){
                this.errorMsg = "La notification doit contenir un message !"
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }else if(this.$v.typeMessage.$invalid){
                this.errorMsg = "Définissez le type de votre message"
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }else{
                const hasHttps = this.linkToNotifyUserGroup?.includes("https://");
                if(this.linkToNotifyUserGroup && hasHttps) {
                    this.$swal.fire('Lien corrompu' , 'Entrer seulement le domaine', 'error');
                    return;
                }
                this.sendNotificationToUsersChoosed(description, title, link);
            }
        },
        async sendNotificationToUsersChoosed(description, title,  link){
            this.btnSendNotificationGroup = true
            let successList = [];
            let failureList = [];
            this.selected.forEach(element => {
                let graphqlQuery = {
                    query: `mutation{ sendNotificationToUser(cat : "Systeme" , body:"${description}", title:"${title}" ,  link: "https://${link}" , userCode:"${element.node.userCode}"){
                            success
                        }
                    }`
                }
                axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                        if (response.data.data.sendNotificationToUser.success === true) {
                            successList.push(element)
                        }else{
                            failureList.push(element)
                        }


                        if (successList.length == this.selected.length) {
                            this.$swal.fire('Notification envoyée' , 'Les utilisateurs ont recu leur notification' , 'success'); 
                            setTimeout(() => {   
                            }, 1000);
                        }

                        if(failureList.length !=0){
                            this.warningMsg = "Certains utilisateurs n'ont pas reçu votre notification !"
                            this.overlay = true;
                            setTimeout(() => {
                                this.overlay = false;
                            }, 4000);
                        }
                    }).catch((e)=>{
                        this.$swal.fire('Notification non envoyee' , e , 'error')
                    }).finally(()=>{
                        this.btnSendNotificationGroup=false;
                    });
                });
            this.dialogNotificationGroupUser = false ;
            this.notificationAlertUserGroup = null
            this.typeMessage = null
            this.linkToNotifyUserGroup = null    
        },


        async findAllUsersConnected(){
            this.isLoadingUserConnectedCount = true
            let graphqlQuery = {
                query : userConnect("user_id")
            }
            await axios.post(getCurrentUrl(), graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.userConnectedCount = response.data.data.userConnect.length
                }
                else{
                    this.userConnectedCount= 0
                }
            }).catch((e)=>{
                this.errorMsg = e;
                this.overlay = true;
                setTimeout(() => {
                    this.overlay = false;
                }, 4000);
            }).finally(()=>{
                this.isLoadingUserConnectedCount = false
            })
        },

        showAlert(type, message) {
            this.alerts[type].show = true;
            this.alerts[type].message = message;
            setTimeout(() => {
                this.alerts[type].show = false;
            }, 4000);
        },

        initDataPolling() {
            this.pollingInterval = setInterval(() => {
                this.getAllAppsert();
                this.getTotalUserCount();
            }, 30000);
        },

        beforeDestroy() {
            console.log('TableauBord being destroyed, cleaning up...');
            if (this.pollingInterval) {
                clearInterval(this.pollingInterval);
            }
            EventBus.$off('devices-count-updated');
            if (this.userCacheInterval) {
                clearInterval(this.userCacheInterval);
            }
        },

        async getAllAppsert() {
            try {
                this.isLoadingDevices = true;
                const graphqlQuery = {
                    query: `
                        query GetAllAppSet {
                        allAppSet {
                            edges {
                                node {
                                    appSetCode
                                    model
                                    version
                                    nbDevices
                                    updateUrl
                                    creationDate
                                }
                            }
                        }
                        }
                    `,
                    variables: {}
                };
                
                console.log('Requête envoyée:', graphqlQuery);
                
                const response = await axios.post('/graphql', graphqlQuery, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).catch(error => {
                    console.error('Détails de l\'erreur:', {
                        status: error.response?.status,
                        statusText: error.response?.statusText,
                        data: error.response?.data,
                        headers: error.response?.headers
                    });
                    throw error;
                });

                if (!response.data) {
                    throw new Error('Réponse invalide du serveur');
                }

                if (response.data.errors) {
                    console.error('Erreurs GraphQL:', response.data.errors);
                    throw new Error(response.data.errors[0].message);
                }
                
                if (response.data?.data?.allAppSet?.edges) {
                    const appList = response.data.data.allAppSet.edges.map(edge => edge.node);

                    const devices = {
                        android: 0,
                        ios: 0,
                        huawei: 0
                    };
                    
                    appList.forEach(app => {
                        if (app.model?.toLowerCase() === 'android') {
                            devices.android = parseInt(app.nbDevices) || 0;
                        } else if (app.model?.toLowerCase() === 'ios') {
                            devices.ios = parseInt(app.nbDevices) || 0;
                        } else if (app.model?.toLowerCase() === 'huawei') {
                            devices.huawei = parseInt(app.nbDevices) || 0;
                        }
                    });
                    
                    this.androidDevices = devices.android;
                    this.iosDevices = devices.ios;
                    this.huaweiDevices = devices.huawei;

                    EventBus.$emit('devices-count-updated', devices);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des appareils:', error);
                this.showAlert('error', 'Erreur lors de la récupération des données des appareils');
            } finally {
                this.isLoadingDevices = false;
            }
        },

        async handleRowClick(item) {
            await this.checkInfoUser(item);
        },

        formatLastLogin(loginhistorySet) {
            if (loginhistorySet?.edges?.length > 0) {
                const lastLogin = loginhistorySet.edges[0].node;
                return {
                    login: lastLogin.loginDatetime ? this.returnDateFormatted(lastLogin.loginDatetime) : 'Jamais',
                    logout: lastLogin.logoutDatetime ? this.returnDateFormatted(lastLogin.logoutDatetime) : 'En ligne'
                };
            }
            return { login: 'Jamais', logout: 'N/A' };
        },

        async switchMode(isProd) {
            try {
                console.log('🔄 Changement de mode:', isProd ? 'Production' : 'Développement');
                this.loading = true;
                
                // Réinitialiser les données et le cache
                await this.$store.dispatch('users/clearCache', 'all');
                this.userList = [];
                this.tempUserList = [];
                this.loadingProgress = 0;
                
                // Mettre à jour le mode dans le store et localStorage
                await this.$store.dispatch('environment/switchEnvironmentMode', isProd);
                localStorage.setItem('appMode', isProd ? 'prod' : 'dev');
                
                // Recharger les données
                console.log('🔄 Rechargement des données après changement de mode');
                await this.getUsersList();
                
                // Afficher le message de succès
                await this.$swal.fire({
                    title: 'Mode changé avec succès',
                    text: `Vous êtes maintenant en mode ${isProd ? 'Production' : 'Développement'}`,
                    icon: 'success'
                });
                
                // Attendre un peu avant de recharger
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                
            } catch (error) {
                console.error('❌ Erreur lors du changement de mode:', error);
                this.$swal.fire({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du changement de mode',
                    icon: 'error'
                });
            } finally {
                this.loading = false;
            }
        },

        async getLastRegisteredUsers() {
            try {
                const query = {
                    query: `
                        query {
                            allUserProfil(first: 100) {
                                edges {
                                    node {
                                        userCode
                                        name
                                        firstname
                                        email
                                        creationDate
                                        phoneNumber
                                    }
                                }
                            }
                        }
                    `
                };

                const response = await axios.post(getCurrentUrl(), query);
                if (response.data?.data?.allUserProfil?.edges) {
                    // Obtenir la date du jour à 00h00
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    
                    // Obtenir la date de demain à 00h00
                    const tomorrow = new Date(today);
                    tomorrow.setDate(tomorrow.getDate() + 1);

                    this.lastUserRegisterList = response.data.data.allUserProfil.edges
                        .filter(edge => edge && edge.node && edge.node.creationDate)
                        .filter(edge => {
                            const creationDate = new Date(edge.node.creationDate);
                            // Vérifier si la date de création est entre aujourd'hui 00h00 et demain 00h00
                            return creationDate >= today && creationDate < tomorrow;
                        })
                        .map(edge => ({
                            node: {
                                userCode: edge.node.userCode || '',
                                name: edge.node.name || '',
                                firstname: edge.node.firstname || '',
                                email: edge.node.email || '',
                                creationDate: this.returnDateFormatted(edge.node.creationDate),
                                phoneNumber: edge.node.phoneNumber || ''
                            }
                        }));

                    console.log('Utilisateurs inscrits aujourd\'hui:', {
                        date: today.toLocaleDateString(),
                        nombre: this.lastUserRegisterList.length,
                        utilisateurs: this.lastUserRegisterList
                    });
                } else {
                    this.lastUserRegisterList = [];
                    console.log('Aucun utilisateur trouvé aujourd\'hui');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des utilisateurs du jour:', error);
                this.showAlert('error', 'Erreur lors de la récupération des utilisateurs du jour');
                this.lastUserRegisterList = [];
            }
        },

        async getTotalTokenPurschasedByUser(userCode) {
            try {
                this.isLoadingTokenPurchasedByUser = true;
                const query = {
                    query: `
                        query {
                            totalTokensPurchasedByUser(userCode: "${userCode}")
                        }
                    `
                };

                const response = await axios.post(getCurrentUrl(), query);
                if (response.status === 200) {
                    this.tokenPurschasedByUser = response.data.data.totalTokensPurchasedByUser;
                } else {
                    this.showAlert('error', 'Erreur lors de la récupération des jetons achetés');
                    this.tokenPurschasedByUser = 0;
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des jetons achetés:', error);
                this.showAlert('error', 'Erreur lors de la récupération des jetons achetés');
                this.tokenPurschasedByUser = 0;
            } finally {
                this.isLoadingTokenPurchasedByUser = false;
            }
        },

        async getTotalTokenUsedByUser(userCode) {
            try {
                this.isLoadingTokenUsedByUser = true;
                const query = {
                    query: `
                        query {
                            totalTokenUsedByUser(userCode: "${userCode}")
                        }
                    `
                };

                const response = await axios.post(getCurrentUrl(), query);
                if (response.status === 200) {
                    this.tokenUsedByUser = response.data.data.totalTokenUsedByUser;
                } else {
                    this.showAlert('error', 'Erreur lors de la récupération des jetons utilisés');
                    this.tokenUsedByUser = 0;
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des jetons utilisés:', error);
                this.showAlert('error', 'Erreur lors de la récupération des jetons utilisés');
                this.tokenUsedByUser = 0;
            } finally {
                this.isLoadingTokenUsedByUser = false;
            }
        },

    },

    mounted(){
        console.log('TableauBord mounted, setting up event listener...');
        
        this.getAllAppsert();
        
        this.pollingInterval = setInterval(() => {
            this.getAllAppsert();
        }, 30000);
        
        EventBus.$on('devices-count-updated', (devices) => {
            console.log('TableauBord received devices update:', devices);
            this.isLoadingDevices = false;
            this.androidDevices = devices.android || 0;
            this.iosDevices = devices.ios || 0;
            this.huaweiDevices = devices.huawei || 0;
        });

        this.findAllUsersConnected();
        this.getTotalUserCount();
        this.getTotalTokenPurchased();
        this.getTotalTokenUsed();
        this.getUsersList();
        this.userPremium();
        this.userPremiumList();
        this.getTotalCodeParrainage();
        this.getLastRegisteredUsers();
    },

    beforeDestroy() {
        console.log('TableauBord being destroyed, cleaning up...');
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
        EventBus.$off('devices-count-updated');
        if (this.userCacheInterval) {
            clearInterval(this.userCacheInterval);
        }
    }
}
</script>

<style scoped>
.userCount{
    font-weight: bold;
    font-size: 35px;
}

.text{
    color: black;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.5
}

.copyright{
    color:white
}

.alert{
    z-index: 2100;
    width: 20%;
}

.alert-success{
    position: fixed;
    display: none;
    right: 0;
}

.alert-error{
    position: fixed;
    display: none;
    right: 0;
}

.alert-warning{
    position: fixed;
    display: none;
    right: 0;
}

.lastRegister{
    font-size: 60px;
}

.text-white{
    color:white
}

.content-image{
    text-align: center;
    align-content: center;
}

.rounded-card{
    border-radius:30px;
}

.cursor-pointer >>> tbody tr {
    cursor: pointer;
}

.v-data-table >>> tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.v-switch {
    margin-top: 0 !important;
}
</style>
