// URLs des environnements
export const PROD_URL = "https://api.busolapp.com/graphql";
export const DEV_URL = "https://dev.sectester.io/graphql";

// URL par défaut (production)
let currentBaseUrl = PROD_URL;

// Fonction pour changer l'URL
export const switchEnvironment = (isProd) => {
    currentBaseUrl = isProd ? PROD_URL : DEV_URL;
    return currentBaseUrl;
};

// Exporter baseUrl comme une valeur constante
export const baseUrl = currentBaseUrl;

// Fonction pour obtenir l'URL courante
export const getCurrentUrl = () => currentBaseUrl;

export const administratorMails = [
    "isaac.tra@icloud.com", "gerardphilippe44@gmail.com","emmanuelhervekouevi@gmail.com", "gowleytizie@proton.me"
];


// PERMETTANT DE PASSER EN MODE DEVELOPPEMENT OU PRODUCTION DE L'APPLICATION
/*export const changeUrlMode = (mode) => {
    let urlMode = "";
    mode == true ? urlMode = baseUrl : urlMode = baseUrlDev ;
    return urlMode ;
}*/
/*-------------------------------------STRING MUTATION OU REQUEST------------------------------------------------------------------------------*/

// CONSULTER TOUS LES BUGS SIGNALÉS
export const allBugs = `query {
    allBug{
        edges{
            node{
                bugCode,
                bugCat,
                modelCv,
                description,
                resolved,
                user{
                    userCode,
                    photo,
                    email,
                    name,
                    firstname,
                    birthDate,
                    sex
                },
                creationDate
            }
        }
    }    
}`

// RECUPERER LES INFORMATIONS DE CV PAR UTILISATEURS
export const cvInfosByUser = (cvModel) => `query {
  cvInfosByUser(cvModel: "${cvModel}") {
    edges {
      node {
        user {
          name
          firstname
        }
        shareCount
        downloadCount
        updateCount
      }
    }
  }
}`

// RECUPERER LES INFORMATIONS DE CV PAR MODEL
export const userConnect = (groupeBy) => `query {
    userConnect(groupBy:"${groupeBy}"){
        groupKey,
        groupValue{
            user{
                userCode,
                photo,
                name,
                firstname,
                creationDate
            }
        }
    }
}`

//--------------------------------------------------------REQUEST FOR USERS ---------------------------------------------------------------//

export const cvInfosByModel = `query {
  cvInfosByModel {
    edges {
      node {
        cvModel
        shareCount
        downloadCount
        updateCount
      }
    }
  }
}`


//-----------------------------------------------------------------PARAINAGE APIs---------------------------------------------------------------//

export const referralUsedUsersVerified = (userCode) => `query {
    referralUsedUsersVerified(userCode:"${userCode}"){
        name,
        firstname,
        email
        
    }
}`


export const referralUsedUsers = (userCode) => `query {
    referralUsedUsersVerified(userCode:"${userCode}"){
        name,
        firstname,
        email
        
    }
}`


export const referralUsedCount = (userCode) => `query {
    referralUsedCount(userCode:"${userCode}")
}`



export const referralUsedVerifiedCount = (userCode) => `query {
    referralUsedVerifiedCount(userCode:"${userCode}")
}`

import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: currentBaseUrl,
    headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Apollo-Require-Preflight': 'true'
    },
    timeout: 10000,
    withCredentials: true
});

// Mettre à jour baseURL quand l'environnement change
axiosInstance.interceptors.request.use(config => {
    config.baseURL = currentBaseUrl;
    return config;
});

// Intercepteur pour gérer les erreurs
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 404) {
            console.error('API endpoint not found:', error.config.url);
        }
        return Promise.reject(error);
    }
);

export { axiosInstance as axios };
