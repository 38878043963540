import Vue from 'vue';
import Vuex from 'vuex';
import { switchEnvironment } from '../Config/globalConst';

Vue.use(Vuex);

export default {
    state: {
        isProduction: localStorage.getItem('appMode') !== 'dev'
    },
    mutations: {
        SET_PRODUCTION_MODE(state, isProd) {
            state.isProduction = isProd;
            localStorage.setItem('appMode', isProd ? 'prod' : 'dev');
        }
    },
    actions: {
        async switchEnvironmentMode({ commit }, isProd) {
            try {
                await switchEnvironment(isProd);
                commit('SET_PRODUCTION_MODE', isProd);
                return true;
            } catch (error) {
                console.error('Erreur lors du changement d\'environnement:', error);
                return false;
            }
        },
        initializeEnvironment({ state }) {
            switchEnvironment(state.isProduction);
        }
    },
    getters: {
        isProduction: state => state.isProduction
    }
}; 