<template>
    <v-app>
        <v-main>
            <v-container>
                <div class="row">
                    <div class="col-lg-3">
                        <v-card class="pa-2" outlined>
                            <div class="d-flex align-center">
                                <v-icon color="primary">mdi-download</v-icon>
                                <span class="ml-2">{{ downloadCount }}</span>
                            </div>
                        </v-card>
                    </div>
                    <div class="col-lg-3">
                        <v-card class="pa-2" outlined>
                            <div class="d-flex align-center">
                                <v-icon color="warning">mdi-pencil</v-icon>
                                <span class="ml-2">{{ updateCount }}</span>
                            </div>
                        </v-card>
                    </div>
                    <div class="col-lg-3">
                        <v-card class="pa-2" outlined>
                            <div class="d-flex align-center">
                                <v-icon color="success">mdi-share-variant</v-icon>
                                <span class="ml-2">{{ shareCount }}</span>
                            </div>
                        </v-card>
                    </div>
                </div>
            </v-container>
            <v-container>
                <span class="title-card">Cv et informations</span><br><br>
                <div class="row">
                    <div class="col-lg-3" v-for="(cv, c) in cvListWithStats" :key="c">
                        <v-card 
                            elevation="2" 
                            hover 
                            @click="getAllCvInfosUserByModel(cv.groupKey)"
                            class="cv-card"
                        >
                            <v-img height="380" :src="cv.image"></v-img>
                            <v-card-text>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>CV Model :</v-list-item-subtitle>
                                            <v-list-item-subtitle class="font-weight-bold">{{ cv.groupKey }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                
                                <!-- Stats pour chaque CV -->
                                <div class="d-flex justify-space-between mt-2">
                                    <div class="text-center">
                                        <v-icon small color="primary">mdi-download</v-icon>
                                        <div class="caption">{{ cv.stats?.downloadCount || 0 }}</div>
                                    </div>
                                    <div class="text-center">
                                        <v-icon small color="warning">mdi-pencil</v-icon>
                                        <div class="caption">{{ cv.stats?.updateCount || 0 }}</div>
                                    </div>
                                    <div class="text-center">
                                        <v-icon small color="success">mdi-share-variant</v-icon>
                                        <div class="caption">{{ cv.stats?.shareCount || 0 }}</div>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-container>
        </v-main>
        <v-overlay :value="overlay">
            <v-progress-circular color="orange" indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-alert
            v-if="successMsg"
            type="success"
            class="alert"
            @input="successMsg = null"
        >
            {{ successMsg }}
        </v-alert>
        <v-alert
            v-if="errorMsg"
            type="error" 
            class="alert"
            @input="errorMsg = null"
        >
            {{ errorMsg }}
        </v-alert>
    </v-app>
</template>

<script>
import axios from 'axios';
//import $ from "jquery"
import { baseUrl, cvInfosByModel } from '../Config/globalConst.js'

// Importation des images depuis src/assets/
const CV_IMAGES = {
    "001": require("@/assets/001.jpg"),
    "002": require("@/assets/002.jpg"),
    "003": require("@/assets/003.jpg"),
    "004": require("@/assets/004.jpg"),
    "005": require("@/assets/005.jpg"),
    "006": require("@/assets/006.jpg"),
    "007": require("@/assets/007.jpg"),
    "008": require("@/assets/008.jpg"),
    "009": require("@/assets/009.jpg"),
    "010": require("@/assets/010.jpg"),
    "011": require("@/assets/011 CV Canadien.jpg"),
    "012": require("@/assets/012.jpg"),
    "013": require("@/assets/013.jpg"),
    "014": require("@/assets/014.jpg"),
    "015": require("@/assets/015.jpg"),
    "016": require("@/assets/016.jpg"),
    "017": require("@/assets/017.jpg"),
    "018": require("@/assets/018.png"),
    "019": require("@/assets/019.jpg"),
    "020": require("@/assets/020.jpg"),
    "022": require("@/assets/022.jpg"),
    "023": require("@/assets/023.jpg"),
    "024": require("@/assets/024.jpg"),
    "025": require("@/assets/025.jpg"),
    "026": require("@/assets/026.jpg"),
};

export default {
    name:"CvInfos",
    data(){
        return{
            errorMsg: null,
            successMsg: null,
            warningMsg: null,

            downloadCount: 0,
            shareCount: 0,
            updateCount: 0,

            overlay: false,

            cvList:[
                { groupKey:"001", image : null,},{ groupKey:"002", image : null,},{ groupKey:"003", image : null,},
                { groupKey:"004", image : null,},{ groupKey:"005", image : null,},{ groupKey:"006", image : null,},
                { groupKey:"007", image : null,},{ groupKey:"008", image : null,},{ groupKey:"009", image : null,},
                { groupKey:"010", image : null,},{ groupKey:"011", image : null,},{ groupKey:"012", image : null,},
                { groupKey:"013", image : null,},{ groupKey:"014", image : null,},{ groupKey:"015", image : null,},
                { groupKey:"016", image : null,},{ groupKey:"017", image : null,},{ groupKey:"018", image : null,},
                { groupKey:"019", image : null,},{ groupKey:"020", image : null,},
                { groupKey:"022", image : null,},{ groupKey:"023", image : null,},{ groupKey:"024", image : null,},
                { groupKey:"025", image : null,},{ groupKey:"026", image : null,},
            ],
            loading : false,
            search : '',
            singleSelect : false,

            selected:[],
            cvStats: {}, // Pour stocker les stats par modèle
        }
    },

    computed: {
        cvListWithStats() {
            return this.cvList.map(cv => ({
                ...cv,
                stats: this.cvStats[cv.groupKey] || { downloadCount: 0, updateCount: 0, shareCount: 0 }
            }));
        }
    },

    methods: {

        // VOIR LA LISTE DES UTILISATEURS AYANT UTILISÉS UN MODEL
        async getAllCvInfosUserByModel( cvModel){
            this.$router.push({name:'CvDetail' , params:{cvModel:cvModel}})
        },
        
        
        // OBTENIR LA LISTE DES CV PAR MODELS
        async getAllCvInfosModel(){
            this.cvList = this.cvList.map(element => {
                try {
                    return {
                        ...element,
                        image: CV_IMAGES[element.groupKey] || 
                              `https://images.ariahapp.com/support/images/${element.groupKey}.jpg`
                    };
                } catch (error) {
                    console.warn(`Image non trouvée pour le CV ${element.groupKey}`);
                    return {
                        ...element,
                        image: `https://images.ariahapp.com/support/images/${element.groupKey}.jpg`
                    };
                }
            });
        },

        // TOTAL DES COMPTES SUR LES OPERATIONS
        async getAllCountOperations() {
            try {
                this.overlay = true; // Afficher le loader
                const graphqlQuery = {
                    query: cvInfosByModel
                };

                const response = await axios.post(baseUrl, graphqlQuery);
                
                if (response.status === 200) {
                    // Réinitialiser les totaux
                    this.downloadCount = 0;
                    this.shareCount = 0;
                    this.updateCount = 0;

                    // Créer un objet pour stocker les stats par modèle
                    const stats = {};

                    response.data.data.cvInfosByModel.edges.forEach(edge => {
                        const node = edge.node;
                        
                        // Mettre à jour les totaux
                        this.downloadCount += node.downloadCount;
                        this.shareCount += node.shareCount;
                        this.updateCount += node.updateCount;

                        // Stocker les stats par modèle
                        stats[node.cvModel] = {
                            downloadCount: node.downloadCount,
                            shareCount: node.shareCount,
                            updateCount: node.updateCount
                        };
                    });

                    this.cvStats = stats;
                }
            } catch (error) {
                this.errorMsg = "Erreur lors de la récupération des données";
                console.error("Erreur:", error);
            } finally {
                this.overlay = false; // Cacher le loader
            }
        }

    },

    mounted(){
        this.getAllCvInfosModel();
        this.getAllCountOperations()
    }
}
</script>

<style scoped>
    .title-card{
        font-size: 18px;
        color: #2f3542;
        font-weight: bold;
    }

    .alert{
        z-index: 2100;
        width: 20%;
    }

    .alert-success{
        position: fixed;
        display: none;
        right: 0;
    }

    .alert-error{
        position: fixed;
        display: none;
        right: 0;
    }

    .alert-warning{
        position: fixed;
        display: none;
        right: 0;
    }

    .libelle-fois{
        color: #130f40;
    }

    .cv-card {
        cursor: pointer;
        transition: transform 0.2s;
    }

    .cv-card:hover {
        transform: translateY(-5px);
    }

    .caption {
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.6);
    }
</style>