<template>
    <v-app>
        <v-container>
            <v-main>
                <div class="mb-4">
                    <v-btn
                        color="orange"
                        @click="$router.go(-1)"
                        small
                        dark
                    >
                        <v-icon left>mdi-arrow-left</v-icon>
                        Retour
                    </v-btn>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <span class="font-weight-bold">Total Téléchargement : <small class="count">{{ downlaodCvCount }}</small></span>
                    </div>
                    <div class="col-lg-3">
                        <span class="font-weight-bold">Total Modification : <small class="count">{{ updateCvCount }}</small></span>
                    </div>
                    <div class="col-lg-3">
                        <span class="font-weight-bold">Total Partages : <small class="count">{{ shareCvCount }}</small></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <v-card>
                            <v-img :src="cvSelected.image"></v-img>
                        </v-card>
                    </div>
                    <div class="col-lg-9">
                        <v-card>
                            <v-card-title><span class="title-card">Information du CV n°{{ $route.params.cvModel}}</span>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headers" :items="usersByCvList" :search="search" :loading="loading">
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-main>
        </v-container>
    </v-app>
</template>

<script>
import axios from 'axios'
import { baseUrl , cvInfosByUser } from '../Config/globalConst'
export default {
    name:"CvItemDetail",
    data(){
        return {
            search:'',
            loading:false,
            headers:[
                { text: 'Nom', value: 'user.name' },
                { text: 'Prenoms', value: 'user.firstname' },
                { text: 'Téléchargement', value: 'downloadCount' },
                { text: 'Modification', value: 'updateCount' },
                { text: 'Partages', value: 'shareCount' },
            ],

            usersByCvList: [],

            cvList:[
                { groupKey:"001", image : null,},{ groupKey:"002", image : null,},{ groupKey:"003", image : null,},
                { groupKey:"004", image : null,},{ groupKey:"005", image : null,},{ groupKey:"006", image : null,},
                { groupKey:"007", image : null,},{ groupKey:"008", image : null,},{ groupKey:"009", image : null,},
                { groupKey:"010", image : null,},{ groupKey:"011", image : null,},{ groupKey:"012", image : null,},
                { groupKey:"013", image : null,},{ groupKey:"014", image : null,},{ groupKey:"015", image : null,},
                { groupKey:"016", image : null,},{ groupKey:"017", image : null,},{ groupKey:"018", image : null,},
                { groupKey:"019", image : null,},{ groupKey:"020", image : null,},
                { groupKey:"022", image : null,},{ groupKey:"023", image : null,},{ groupKey:"024", image : null,},
                { groupKey:"025", image : null,},{ groupKey:"026", image : null,},
            ],

            cvSelected: {
                groupKey : null,
                image: null
            }
        }
    },

    methods: {
        // OBTENIR LA LISTE DES CV PAR MODELS
        async getAllCvInfosModel(){
            this.cvList.forEach(element => {
                if (element.groupKey == this.$route.params.cvModel) {
                    // Essayer d'abord le format JPG
                    try {
                        this.cvSelected.image = require(`@/assets/${element.groupKey}.jpg`);
                    } catch (e) {
                        try {
                            // Si JPG échoue, essayer PNG
                            this.cvSelected.image = require(`@/assets/${element.groupKey}.png`);
                        } catch (e2) {
                            // Si les deux échouent, utiliser l'URL distante
                            this.cvSelected.image = `https://images.ariahapp.com/support/images/${element.groupKey}.jpg`;
                        }
                    }
                }
            });
        },

        // OBTENIR LA LISTE DES UTILISATEURS AYANT UTILISÉS LE MODEL DE CV
        async getAllUserByCvModel(){
            this.loading = true;
            let graphqlQuery = {
                query: cvInfosByUser(this.$route.params.cvModel)
            }
            try {
                const response = await axios.post(baseUrl, graphqlQuery);
                if (response.status === 200) {
                    this.usersByCvList = response.data.data.cvInfosByUser.edges.map(edge => ({
                        user: edge.node.user,
                        downloadCount: edge.node.downloadCount,
                        shareCount: edge.node.shareCount,
                        updateCount: edge.node.updateCount
                    }));
                }
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        }
    },

    computed:{
        downlaodCvCount(){
            return this.usersByCvList.reduce((total, user) => total + user.downloadCount, 0);
        },

        updateCvCount(){
            return this.usersByCvList.reduce((total, user) => total + user.updateCount, 0);
        },

        shareCvCount(){
            return this.usersByCvList.reduce((total, user) => total + user.shareCount, 0);
        },
    },

    mounted(){
        this.getAllCvInfosModel();
        this.getAllUserByCvModel();
    }
}
</script>

<style scoped>
    .title-card{
        font-size: 18px;
        color: #2f3542;
        font-weight: bold;
    }

    .count{
        font-size: 20px;
        color: #222f3e;
    }
</style>