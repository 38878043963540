<template>
    <v-card>
        <v-card-title><span>RECHARGER UN UTILISATEUR</span></v-card-title>
        <v-card-text>
            <v-container>
                <form>
                    <div class="form-group">
                        <label>Réference du rechargement:</label>
                        <v-select :items="labelTransaction" item-text="label" item-value="label" type="text" :error-messages="labelDepositError" dense outlined v-model.trim="$v.labelDeposit.$model"></v-select>
                        <small id="emailHelp" class="form-text text-muted">Saisissez un libellé pour le rechargement? </small>
                    </div>

                    <div class="form-group">
                        <label>Nombre de jetons:</label>
                        <v-select :items="tokenItems" item-text="amount" item-value="amount" type="number" min="0" :error-messages="tokenToSendError" dense outlined v-model.number="$v.tokenToSend.$model"></v-select>
                        <small id="emailHelp" class="form-text text-muted">Combien de <strong>ARI</strong> voulez vous envoyer ? </small>
                    </div>
                    <small id="emailHelp" class="form-text text-muted">NB : (*) devant un champs signifie qu'il est obligatoire.</small>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn variant="text" color="orange" @click="sendTokenToUser(tokenToSend,labelDeposit,userTransaction.userCode)" :loading="loading" :disabled="loading">
                RECHARGER
            </v-btn>
        </v-card-actions>

        <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate color="orange"></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
import { baseUrl } from '@/Config/globalConst';
import axios from 'axios';
import $ from 'jquery';
import { numeric, required } from 'vuelidate/lib/validators';

export default {
    props:{
        userTransaction: Object,
    },
    name:"TransfertToken",
    data(){
        return{
            loading: false,
            labelDeposit: null,
            tokenToSend: null,
            labelTransaction : [
                { label: "Achat de jetons" },
                { label : "Bonus "}
            ],
            tokenItems : [
                { amount : 3},
                { amount : 5},
                { amount : 10}
            ],
        }
    },

    validations:{
        tokenToSend:{
            required , numeric
        },
        labelDeposit:{
            required
        }
    },

    methods:{
        resetForm() {
            this.labelDeposit = null;
            this.tokenToSend = null;
            this.$v.$reset();
        },

        // ENVOYER LES JETONS A UN UTILISATEUR
        async sendTokenToUser(amount, label, userCode) {
            this.$v.$touch();
            if(this.$v.labelDeposit.$invalid) {
                this.errorMsg = "Selectionnez un label"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
                return;
            }
            
            if(this.$v.tokenToSend.$invalid) {
                this.errorMsg = "Le champ de jetons est obligatoire"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
                return;
            }

            try {
                const result = await this.$swal({
                    title: "Envoyer les jetons?",
                    text: "Êtes vous sure ? Attention cette action est irréversible",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#c0392b",
                    confirmButtonText: "Oui, Recharger!"
                });

                if (result.isConfirmed) {
                    this.loading = true;
                    const graphqlQuery = {
                        query: `mutation{
                            payTokens(amount:${amount}, label:"${label}", userCode:"${userCode}"){
                                success
                            }
                        }`
                    };

                    const response = await axios.post(baseUrl, graphqlQuery);
                    
                    if (response.data.data.payTokens.success === true) {
                        await this.$swal.fire('Rechargement Réussi', "L'utilisateur a été rechargé", 'success');
                        this.resetForm();
                        this.$emit('transfer-success');
                    } else {
                        throw new Error("Problème Interne");
                    }
                }
            } catch (error) {
                this.$swal.fire('Rechargement échoué', error.message || "Une erreur est survenue", 'error');
            } finally {
                this.loading = false;
            }
        }
    },

    computed:{
        labelDepositError(){
            if (!this.$v.labelDeposit.$dirty) return [];
            const errors =  [] ;
            !this.$v.labelDeposit.required && errors.push("Choisissez une reference !");
            return errors ;
        },

        tokenToSendError(){
            if (!this.$v.tokenToSend.$dirty) return [];
            const errors =  [] ;
            !this.$v.tokenToSend.required && errors.push(" Combien de jetons voulez-vous envoyer ?");
            !this.$v.tokenToSend.numeric && errors.push(" Mauvaise saisie. le token doit être un nombre !");
            return errors ;
        },
    }
}
</script>
