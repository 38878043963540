import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes
const BATCH_SIZE = 100; // Taille des lots pour le chargement

export default {
    namespaced: true,
    state: {
        userList: [],
        userCache: {
            data: new Map(),
            timestamp: null,
            expiryTime: CACHE_DURATION,
            isPreloading: false
        },
        premiumUsersCache: {
            data: [],
            timestamp: null,
            expiryTime: CACHE_DURATION
        },
        isLoading: false,
        lastUpdate: null,
        preloadedData: null
    },

    mutations: {
        SET_USER_LIST(state, users) {
            state.userList = users;
            state.lastUpdate = Date.now();
        },
        SET_LOADING(state, status) {
            state.isLoading = status;
        },
        SET_PRELOADING(state, status) {
            state.userCache.isPreloading = status;
        },
        UPDATE_CACHE(state, { type, data }) {
            const now = Date.now();
            let newMap;

            switch (type) {
                case 'users':
                    // Utiliser une Map pour un accès plus rapide
                    newMap = new Map();
                    data.forEach(user => {
                        newMap.set(user.node.userCode, {
                            ...user,
                            node: {
                                ...user.node,
                                numTokens: user.node.numTokens || 0,
                                tokensIsBlocked: user.node.tokensIsBlocked || false,
                                tokenExpirationDate: user.node.tokenExpirationDate || null
                            },
                            timestamp: now
                        });
                    });
                    state.userCache.data = newMap;
                    state.userCache.timestamp = now;
                    break;
                case 'premium':
                    state.premiumUsersCache.data = data.map(user => ({
                        ...user,
                        timestamp: now
                    }));
                    state.premiumUsersCache.timestamp = now;
                    break;
            }
        },
        SET_PRELOADED_DATA(state, data) {
            state.preloadedData = data;
        },
        CLEAR_CACHE(state, type) {
            switch (type) {
                case 'users':
                    state.userCache.data.clear();
                    state.userCache.timestamp = null;
                    break;
                case 'premium':
                    state.premiumUsersCache.data = [];
                    state.premiumUsersCache.timestamp = null;
                    break;
                case 'all':
                    state.userCache.data.clear();
                    state.userCache.timestamp = null;
                    state.premiumUsersCache.data = [];
                    state.premiumUsersCache.timestamp = null;
                    state.preloadedData = null;
                    break;
            }
        }
    },

    actions: {
        async updateUserList({ commit }, users) {
            commit('SET_LOADING', true);
            try {
                commit('SET_USER_LIST', users);
                commit('UPDATE_CACHE', { type: 'users', data: users });
            } finally {
                commit('SET_LOADING', false);
            }
        },

        clearCache({ commit }, type = 'all') {
            commit('CLEAR_CACHE', type);
        },

        async preloadNextBatch({ state, commit }) {
            if (state.userCache.isPreloading) return;
            
            commit('SET_PRELOADING', true);
            try {
                // Logique de préchargement du prochain lot de données
                const currentSize = state.userList.length;
                const nextBatch = await this.fetchUserBatch(currentSize, BATCH_SIZE);
                
                if (nextBatch && nextBatch.length > 0) {
                    commit('SET_PRELOADED_DATA', nextBatch);
                }
            } finally {
                commit('SET_PRELOADING', false);
            }
        },

        async getUsersFromCache({ state }) {
            const now = Date.now();
            const cache = state.userCache;

            if (cache.timestamp && (now - cache.timestamp) < cache.expiryTime) {
                return Array.from(cache.data.values())
                    .filter(item => item.timestamp && (now - item.timestamp) < cache.expiryTime)
                    .map(item => item.user);
            }
            return null;
        }
    },

    getters: {
        getUserList: state => state.userList,
        getCachedUsers: state => Array.from(state.userCache.data.values())
            .map(item => item.user),
        getCachedPremiumUsers: state => state.premiumUsersCache.data,
        isCacheValid: state => type => {
            const now = Date.now();
            switch (type) {
                case 'users':
                    return state.userCache.timestamp && 
                           (now - state.userCache.timestamp) < state.userCache.expiryTime;
                case 'premium':
                    return state.premiumUsersCache.timestamp && 
                           (now - state.premiumUsersCache.timestamp) < state.premiumUsersCache.expiryTime;
                default:
                    return false;
            }
        },
        hasPreloadedData: state => !!state.preloadedData,
        getPreloadedData: state => state.preloadedData
    }
}; 