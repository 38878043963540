import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import "sweetalert2/dist/sweetalert2.min.css"
import Vue from 'vue'
import excel from 'vue-excel-export'
import VueSweetalert2 from 'vue-sweetalert2'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(excel);

// Configuration globale d'Axios
axios.defaults.baseURL = 'https://api.busolapp.com'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

// Intercepteur pour gérer les erreurs globalement
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 400) {
      console.error('Erreur de requête GraphQL:', error.response.data)
    }
    return Promise.reject(error)
  }
)

// Initialiser l'environnement au démarrage
store.dispatch('initializeEnvironment');

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
